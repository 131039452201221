export enum UtilitiesProjectType {
    'Energy',
    'Water',
    'Waste',
    'SolarPV',
    'Mobility',
    'Lighting',
    'Metering',
    'GreenBuilding',
    'Generator'
}