export enum APDocumentType {
    '30 Day',
    'Contract',
    'Contract Batch',
    'Letting Commission',
    'Tenant Installation',
    'Tenant Refund',
    'Adhoc',
    'Utilities',
    'Supplier Tracking List',
    'Recon Query Analysis',
}