export enum LeaseDocumentDescriptionPrefix {
    'ABSA',
    'Addendum',
    'AOD Undertaking',
    'AOL',
    'Archive Request',
    'Bank Code Correspondence',
    'Bank Details Confirmation',
    'Bank Guarantee Cancelled',
    'Bank Guarantee',
    'Blacklisting',
    'Breach Letter',
    'Cancellation Agreement',
    'Cancellation AOL Vetting',
    'Cession',
    'Debit Order Bank Details Auth',
    'Debit Order Deleted',
    'Debit Order Limit Change',
    'E-mail Consent',
    'Holding Company Guarantee',
    'Identification Docs',
    'Insurance Guarantee',
    'Investec',
    'LAF Cession',
    'LAF Change',
    'LAF New Lease',
    'LAF Renewal Lease',
    'LCF Abscond',
    'LCF Entity Ceases',
    'LCF Handover',
    'LCF Landlord Elect',
    'LCF Mutual',
    'LCF Tenant Elect',
    'Lease Clause Changes',
    'Lease Consent',
    'Lease Document Changes',
    'Lease Early Cancellation Letter',
    'Lease Instruction',
    'Lease Negotiations',
    'Lease RMS Notification',
    'Lease Schedule',
    'Lease to Tenant for Signature',
    'Lease Unit Config',
    'Lease Unsigned Leases',
    'Leases to Strongroom',
    'Legal Vacate Notice',
    'Liquidation Notice',
    'Nedbank',
    'New Instruction Request Legal',
    'Offer',
    'Panic Bottom',
    'Parking',
    'Refund Bank Details Auth Rejected',
    'Refund Bank Details Auth',
    'Std Bank',
    'Storeroom',
    'TIF Follow-up',
    'TIF no Consent',
    'TIF with Consent',
    'TPN Report Cession Lease',
    'TPN Report Individual Lease',
    'TPN Report New Lease',
    'TPN Report Renewal Lease'
}