export enum InstrumentType{
    'Swaps',
    'Listed Bonds',
    'Cross Currency',
    'FEC',
    'Unlisted Bonds',
    'Vendor Loans',
    'Common Paper',
    'Bank Loans',
    'Shareholder Loans'
}