import { Configuration } from "@azure/msal-browser";

// config.ts
export const config = {
    local: {
        endpoint: 'https://localhost:44392/api',
        clientId: '7055efbc-988e-4344-90f4-0022a8eaf309',
    },
    development: {
      endpoint: 'https://xc-d-ecm-api.azurewebsites.net/api',
      clientId: "7055efbc-988e-4344-90f4-0022a8eaf309",
    },
    beta: {
        endpoint: 'https://ecm-beta01.azurewebsites.net/api',
        clientId: "05512940-5734-4c3e-b9e0-9f119aa641c9",
    },
    production: {
      endpoint: 'https://rp-be-ecm01.azurewebsites.net/api',
      clientId: "05512940-5734-4c3e-b9e0-9f119aa641c9",
    },
    // Add other environments as needed
  };

  export const msalConfig: Configuration = {
    auth: {
      clientId:'c997339f-b870-4550-b3e0-875ef9bd2e98',
      redirectUri: window.location.origin,
      authority: "https://login.microsoftonline.com/79eeeebb-af45-44c1-ae28-b6b489fa76c7/oauth2/v2.0/authorize"
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };

  export const loginRequest = {
    scopes: ["User.Read"]
  };