import * as React from "react";
import { Field, FieldProps, makeStyles } from "@fluentui/react-components";
import { DatePicker, DatePickerProps } from "@fluentui/react-datepicker-compat";
const useStyles = makeStyles({
  control: {
    // maxWidth: "300px",
  },
});
interface DatePicker {
    datePickerProps: DatePickerProps;
    fieldProps: FieldProps;
}
const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : (date.getFullYear() % 100)+
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate() ;
};
export const Default = (props: DatePicker) => {
  const styles = useStyles();
  const [value, setValue] = React.useState<Date | null | undefined>(null);
  const datePickerRef = React.useRef<HTMLInputElement>(null);
  const onClick = React.useCallback((): void => {
    setValue(null);
    datePickerRef.current?.focus();
  }, []);
  React.useEffect(() => {
    if (value !== null) {
      // // format the date to a string parseable by the date picker
      // const formattedDate = onFormatDate(value);
      // // set the value of the date picker
      // datePickerRef.current.value = formattedDate;
      // send value to parent component
      if (props.datePickerProps.onSelectDate) {
        props.datePickerProps.onSelectDate(value);
      }
    }
  }, [value]);
  React.useEffect(() => {
  if (props.fieldProps.label === "EmailDate") {
    // get the email date of the email
    const emailDate = Office.context.mailbox.item.dateTimeCreated;
    // set default value to the email address of the recipients, if there are multiple recipients, separate them with a semicolon
    // props.datePickerProps.value = emailDate;
    setValue(emailDate);
  }
}, [props.fieldProps.label]);
  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [value]
  );
  return (
    <Field label={props.fieldProps.label}>
      <DatePicker
        ref={datePickerRef}
        value={value}
        parseDateFromString={onParseDateFromString}
        formatDate={onFormatDate}
        className={styles.control}
        placeholder="Select a date..."
        {...props.datePickerProps}
      />
    </Field>
  );
};