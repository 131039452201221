import { ComboboxProps, Field, Combobox, Option } from "@fluentui/react-components";
import React, {useEffect, useState} from "react";

interface OwnerEntity {
    ownerEntityID: number;
    ownerEntityName: string;
}

interface OwnerEntityProps {
    ownerEntityID: number;
    onOwnerEntityChange: (ownerEntityId: string) => void;
}
const OwnerEntity = ({...ownerEntityProps}: Partial<OwnerEntityProps>, props: Partial<ComboboxProps>) => {
    const [matchingOptions, setMatchingOptions] = React.useState([] as OwnerEntity[]);
    const [ownerEntities, setOwnerEntities] = React.useState([] as OwnerEntity[]);
    useEffect(() => {
        if (ownerEntityProps.ownerEntityID !== undefined) {
            getOwnerEntities();
        }
    }, [ownerEntityProps.ownerEntityID]);

    useEffect(() => {
        // the owner entities are fetched, set the default value
        if (ownerEntities.length > 0) {
            // set the default value to owner entity id
            let id = ownerEntityProps.ownerEntityID;
            let ownerEntity = ownerEntities.find((p) => p.ownerEntityID == id);
            if (ownerEntity) {
                ownerEntityProps.onOwnerEntityChange(ownerEntity.ownerEntityID.toString());
            }
        }
    }, [ownerEntities]);

    const getOwnerEntities = async () => {
        try {
            let url = `https://localhost:44392/api/MDA/OwnerEntities?OwnerEntityId=${ownerEntityProps.ownerEntityID}`;
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            setOwnerEntities(data.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.trim();
        const matches = matchingOptions.filter((option) => {
            if (!isNaN(parseInt(value))) {
                return option.ownerEntityID.toString().includes(value.toLowerCase());
            } else {
                return option.ownerEntityName.toLowerCase().includes(value.toLowerCase());
            }
        });
        setMatchingOptions(matches);
    }

    const handleSelection = (event, data) => {
        console.log(data, event);
        ownerEntityProps.onOwnerEntityChange(data.optionValue);
    }

    return (
        <>
            <Field label="Owner Entity">
                <Combobox
                    disabled={ownerEntityProps.ownerEntityID === undefined}
                    placeholder="Search for an owner entity"
                    id="ownerEntity"
                    onChange={handleSearchTermChange}
                    onOptionSelect={handleSelection}
                    {...props}>
                        {matchingOptions.length == 0 && ownerEntities.map((option) => (
                            <Option key={option.ownerEntityID} value={option.ownerEntityID.toString()} text={option.ownerEntityName}>
                                {option.ownerEntityName}
                            </Option>
                        ))}
                        {matchingOptions.length > 0 && matchingOptions.map((option) => (
                            <Option key={option.ownerEntityID} value={option.ownerEntityID.toString()} text={option.ownerEntityName}>
                                {option.ownerEntityName}
                            </Option>))}
                    </Combobox>
            </Field>
        </>
    );
}

export default OwnerEntity;