export enum BusinessUnit {
   'AccountsPayable',
   'Acquisitions&Disposals',
   'AssetManagement',
   'Banking',
   'RiskCompliance',
   'CorporateFinance',
   'CreditControl',
   'Development',
   'FacilitiesManagement',
   'HumanResourcesPayroll',
   'IT',
   'Marketing',
   'Non-GLA',
   'Operational Finance',
   'Procurement',
   'TenantBilling',
   'LegalandCompanySec',
   'PropertyManagement',
   'TreasuryManagement',
   'UtilitiesManagement',
   'Other',
}