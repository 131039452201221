export enum AccountNumber {
'Standard Bank_420988947',
'Standard Bank_401790223',
'Standard Bank_1976796',
'Standard Bank_477546',
'Standard Bank_32951205',
'Standard Bank_20989288',
'Standard Bank_1955225',
'Standard Bank_401805379',
'Standard Bank_1640488',
'Standard Bank_2014653',
'Standard Bank_436361',
'Standard Bank_1996703',
'Standard Bank_401766535',
'Standard Bank_282478299',
'Standard Bank_22722580',
'Standard Bank_242132812',
'Standard Bank_242138861',
'Standard Bank_43004067',
'Standard Bank_332654621',
'Standard Bank_422139106',
'Standard Bank_421058889',
'Standard Bank_420943013',
'Standard Bank_421033037',
'Standard Bank_422139114',
'Standard Bank_22704337',
'Standard Bank_22721002',
'Standard Bank_22673652',
'Standard Bank_22693033',
'Standard Bank_22712461',
'Standard Bank_220702713',
'Standard Bank_252327128',
'Standard Bank_420943226',
'Standard Bank_22669159',
'Standard Bank_28726235',
'Standard Bank_252306775',
'Standard Bank_0000078707676',
'Standard Bank_0000301878099',
'Standard Bank_252311418',
'Standard Bank_000618481281',
'Standard Bank_220725128',
'Standard Bank_408828951',
'Standard Bank_252315502',
'Standard Bank_638500745',
'Standard Bank_23069007',
'Standard Bank_28737466',
'Standard Bank_1904388',
'Standard Bank_202271277',
'Standard Bank_372878458',
'Standard Bank_28726251',
'Standard Bank_372879772',
'Standard Bank_28736567',
'Standard Bank_022665676',
'Standard Bank_200820486',
'Standard Bank_220704937',
'Standard Bank_372879764',
'Standard Bank_728525518',
'Standard Bank_000576433',
'Standard Bank_426182219000',
'Standard Bank_728744058009',
'FNB_62011784669',
'FNB_62544596937',
'FNB_62445408108',
'FNB_62028265818',
'FNB_62325420636',
'FNB_62619635222',
'FNB_62619815402',
'FNB_62475938472',
'FNB_62284063874',
'FNB_62280517544',
'FNB_62316560714',
'FNB_62483580869',
'FNB_62791588373',
'FNB_62704273937',
'Nedbank(CC)_3768000004366650',
'Nedbank(CC)_3768000004280539',
'Nedbank_NDN57264',
'Nedbank_8583475',
'Nedbank_8583476',
'Nedbank_037881165634',
'Nedbank_1008189421',
'Nedbank_037881115017',
'Nedbank_1084270994',
'Nedbank_1129049914',
'Investec_1300201887502',
'Investec_1300201887503',
'Investec_1517481',
'Investec_1598549',
'RMB_62873989712',
'RMB_62889095264',
'NinetyOne_1221380',
'NinetyOne_1221381',
'ABSA_4065820447',
}



