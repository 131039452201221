export class Item {
    id?: string;
    name: string;
    size: string;
    containerID?: string;
    classificationJson: {};
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    webUrl?: string;
    itemInputTypeID?: string;
  }