import { Button, Input, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import AttachmentTable from './AttachmentTable';
import { uploadSPfileFolder } from '../../services/endpoint';
import { TagService } from '../../services/tag.service';

interface AttachmentProps {
    departmentId: string;
    driveId: string;
    folderId: string;
    sharepointUploadResult: (fileInfo: any[]) => void;
}

type Attachment = {
    id: string;
    name: any;
};

const Attachment: React.FC<AttachmentProps> = ({ departmentId, driveId, folderId, sharepointUploadResult }) => {
    const toasterId = useId("toaster");
    const [attachments, setAttachments] = React.useState<Attachment[]>([]);
    const [selectedAttachments, setSelectedAttachments] = React.useState<Attachment[]>([]);
    const [mergedDocumentName, setMergedDocumentName] = React.useState<string>('');
    const [fileSharePointIds, setFileSharePointIds] = React.useState<string[]>([]);
    const [uploadedFileInfo, setUploadedFileInfo] = React.useState<any[]>([]);
    const { dispatchToast } = useToastController(toasterId);
    const tagService = new TagService();
    useEffect(() => {
        // check if the uploadedFileInfo is not empty and is the same length as the selectedAttachments
        if (uploadedFileInfo.length > 0 && uploadedFileInfo.length === selectedAttachments.length) {
            // call the sharepointUploadResult function
            sharepointUploadResult(uploadedFileInfo);

            // clear the selected attachments
            setSelectedAttachments([]);

            // clear the attachments
            setAttachments([]);
        }
    }, [uploadedFileInfo]);
    useEffect(() => {
        console.log({attachments});
    }, [attachments]);
    const handleDownload = () => {
        const mailbox = Office.context.mailbox;
        //only get attachment the a added to the email and not the ones part of the email signature
        const _attachments = mailbox.item.attachments.filter((attachment) => {
            return attachment.isInline !== true;
        });
        // get attachment content
            _attachments.forEach((attachment) => {
                // get attachment content
                setAttachments((prev) => [
                    ...prev,
                    {
                        name: attachment.name,
                        id: attachment.id,
                    },
                ]);
            });
        // download the email
        mailbox.item.body.getAsync(Office.CoercionType.Html, (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                setAttachments((prev) => [
                    ...prev,
                    {
                        name: "email",
                        id: "email",
                    },
                ]);
            }
        });  
    };

    const uploadAttachmentToSharepoints = async () => {
        try {
        const mailbox = Office.context.mailbox;
        console.log(selectedAttachments)
        // get email attachments
        const _attachments = mailbox.item.attachments.filter((attachment) => {
            return attachment.isInline !== true && selectedAttachments.some((selected) => selected.id === attachment.id);
        });

        // upload _attachments to sharepoint
        const uploadPromises = _attachments.map(async (attachment) => {
            return new Promise<void>(async (resolve, reject) => {
            //get attachment content
            mailbox.item.getAttachmentContentAsync(attachment.id, async (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    console.log(result);
                    // setAttachmentContent(result.value);
                    await sharepointUpload(attachment, result.value);
                    if (selectedAttachments.some((selected) => selected.id === "email")) {
                        await upoadEmailContent(mailbox);
                        resolve();
                    } else {
                        resolve();
                    }
                } else {
                    console.log(result.error.message);
                    reject(result.error.message);
                }
            });
            if (attachment.id === "email") {
                // get email content
                mailbox.item.body.getAsync(Office.CoercionType.Html, async (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        console.log(result.value);
                        // setAttachmentContent(result.value);
                        await sharepointUpload(attachment, result.value);
                        resolve();
                    } else {
                        console.log(result.error.message);
                        reject(result.error.message);
                    }
                });
            }
        });
        });

        await Promise.all(uploadPromises);

        console.log("All attachments uploaded successfully");
        // if the number of uploaded files is the same as the selected attachments then show a toast message
        if (uploadedFileInfo.length === selectedAttachments.length) {
            dispatchToast(
                <Toast>
                    <ToastTitle>All attachments uploaded successfully</ToastTitle>
                </Toast>,
                { intent: "success" }
            );
        } else {
            dispatchToast(
                <Toast>
                    <ToastTitle>Some attachments failed to upload</ToastTitle>
                </Toast>,
                { intent: "error" }
            );
        }
    } catch (error) {
        console.error(error);
        dispatchToast(
            <Toast>
                <ToastTitle>Error uploading attachments</ToastTitle>
            </Toast>,
            { intent: "error" }
        );

        // rollback the uploaded attachments
        uploadedFileInfo.forEach((file) => {
            rollbackSharepointUpload(file.id);
        });

        // clear the selected attachments
        setSelectedAttachments([]);

        // clear the attachments
        setAttachments([]);
    }
    };

    const uploadEmailAttchmentAndEmailContentToSharepoint = async () =>{
        const mailbox = Office.context.mailbox;
        // get email attachments
        const _attachments = mailbox.item.attachments.filter((attachment) => {
            return attachment.isInline !== true && selectedAttachments.some((selected) => selected.id === attachment.id);
        });



        // upload _attachments to sharepoint
        const uploadPromises = _attachments
            .filter((attachment) => selectedAttachments.some((selected) => selected.id === attachment.id))
            .map(async (attachment) => {
            return new Promise<void>(async (resolve, reject) => {
                // get attachment content
                mailbox.item.getAttachmentContentAsync(attachment.id, async (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    console.log(result);
                    // setAttachmentContent(result.value);
                    await sharepointUpload(attachment, result.value);
                    resolve();
                } else {
                    console.log(result.error.message);
                    reject(result.error.message);
                }
                });
            });
            });

        // Check if email content needs to be uploaded
        if (selectedAttachments.some((selected) => selected.id === "email")) {
            uploadPromises.push(
            new Promise<void>(async (resolve, reject) => {
                mailbox.item.body.getAsync(Office.CoercionType.Html, async (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    console.log(result.value);
                    await upoadEmailContent(mailbox);
                    resolve();
                } else {
                    console.log(result.error.message);
                    reject(result.error.message);
                }
                });
            })
            );
        }

        await Promise.all(uploadPromises);

        // if the number of uploaded files is the same as the selected attachments then show a toast message
        if (uploadedFileInfo.length === selectedAttachments.length) {
            console.log("All attachments uploaded successfully");
            dispatchToast(
                <Toast>
                    <ToastTitle>All attachments uploaded successfully</ToastTitle>
                </Toast>,
                { intent: "success" }
            );
        } else {
            dispatchToast(
                <Toast>
                    <ToastTitle>Some attachments failed to upload</ToastTitle>
                </Toast>,
                { intent: "error" }
            );
        }
    }

    async function upoadEmailContent(mailbox: any) {
        // get email content
        await mailbox.item.body.getAsync(Office.CoercionType.Html, async (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                console.log(result.value);
                // upload email content to sharepoint
                await sharepointUpload({ name: "email", content: result.value}, result.value);
            } else {
                console.log(result.error.message);
            }
        });
    }

    async function sharepointUpload(fileInfo: any, content: any) {
        const _folderId = folderId;
        const _driveId = driveId;
        const _siteId = departmentId;
        // make sure that the folderId, driveId and siteId are not empty
        if ((_folderId.length == 0 || _folderId === undefined) || (_driveId.length == 0 || _driveId === undefined) || (_siteId.length == 0 || _siteId === undefined)) {
            console.log("FolderId, DriveId or SiteId is empty");
            // show a toast message
            dispatchToast(
                <Toast>
                    <ToastTitle>FolderId, DriveId or SiteId is empty</ToastTitle>
                </Toast>,
                { intent: "error"}
            );
            return;
        }
        if (fileInfo.name === "email") {
            return await tagService.uploadEmailToSharepoint(fileInfo, _siteId, _driveId, _folderId).then((response) => 
               { if (response) {
                console.log(response);
                setUploadedFileInfo((prev) => [...prev, response]);
                } else {
                    console.error(response);
                    // show a toast message
                    dispatchToast(
                        <Toast>
                            <ToastTitle>Error uploading email</ToastTitle>
                        </Toast>,
                        { intent: "error" }
                    );
                }
            }).catch((error) => {
                console.error(error);
                // show a toast message
                dispatchToast(
                    <Toast>
                        <ToastTitle>Error uploading email</ToastTitle>
                    </Toast>,
                    { intent: "error" }
                );
            });

            // if (sharepointUploadResponse) {
            //     console.log(sharepointUploadResponse);
            //     // show a toast message
            //     dispatchToast(
            //         <Toast>
            //             <ToastTitle>Email uploaded successfully</ToastTitle>
            //         </Toast>,
            //         { intent: "success" }
            //     );

            //     // set the uploadedFileInfo state with the sharepointUploadResponse
            //     setUploadedFileInfo((prev) => [...prev, sharepointUploadResponse]);

            //     return sharepointUploadResponse;
            // }
        } else {
            // upload file to sharepoint using the uploadSPfileFolder
            return await tagService.uploadAttachmentToSharepoint(fileInfo, content, _siteId, _driveId, _folderId).then((response) => {
                if (response) {
                    console.log(response);
                    setUploadedFileInfo((prev) => [...prev, response]);
                } else {
                    console.error(response);
                    // show a toast message
                    dispatchToast(
                        <Toast>
                            <ToastTitle>Error uploading attachment</ToastTitle>
                        </Toast>,
                        { intent: "error" }
                    );
                }
            }).catch((error) => {
                console.error(error);
                // show a toast message
                dispatchToast(
                    <Toast>
                        <ToastTitle>Error uploading attachment. {error.error}</ToastTitle>
                    </Toast>,
                    { intent: "error" }
                );
            });
            // if (sharepointUploadResponse) {
            //     console.log(sharepointUploadResponse);
            //     // show a toast message
            //     dispatchToast(
            //         <Toast>
            //             <ToastTitle>Attachment uploaded successfully</ToastTitle>
            //         </Toast>,
            //         { intent: "success" }
            //     );

            //     // set the uploadedFileInfo state with the sharepointUploadResponse
            //     setUploadedFileInfo((prev) => [...prev, sharepointUploadResponse]);

            //     return sharepointUploadResponse;
            // }
        }
    }

    async function rollbackSharepointUpload(fileId: string) {
        try {
            // rollback the sharepoint upload
            const rollbackResponse = await tagService.rollbackSharepointUpload(departmentId, driveId, folderId, fileId);

            if (rollbackResponse) {
                console.log(rollbackResponse);
                // show a toast message
                dispatchToast(
                    <Toast>
                        <ToastTitle>Attachment upload rolled back successfully</ToastTitle>
                    </Toast>,
                    { intent: "success" }
                );

                // set the uploadedFileInfo state with the sharepointUploadResponse
                setUploadedFileInfo((prev) => prev.filter((file) => file.id !== fileId));
            }
        } catch (error) {
            console.error(error);
            // show a toast message
            dispatchToast(
                <Toast>
                    <ToastTitle>Error rolling back attachment upload</ToastTitle>
                </Toast>,
                { intent: "error" }
            );
        }
    }

    function _base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
    }

    const handleMerge = async () => {
        const mailbox = Office.context.mailbox;
        if (selectedAttachments.length === 0 || mergedDocumentName.trim() === '') {
            dispatchToast(
                <Toast>
                    <ToastTitle>Please select attachments and specify a name for the merged document</ToastTitle>
                </Toast>,
                { intent: "error" }
            );
            return;
        }

        // check if the selected files are the same file type
        const fileTypes = selectedAttachments.map((attachment) => attachment.name.split('.').pop());
        const isSameFileType = fileTypes.every((val, _i, arr) => val === arr[0]);

        if (!isSameFileType) {
            dispatchToast(
                <Toast>
                    <ToastTitle>Selected attachments are not the same file type</ToastTitle>
                </Toast>,
                { intent: "error" }
            );
            return;
        }

        // using the the pdf-lib library to merge the selected attachments
        const { PDFDocument } = require('pdf-lib');
        const mergedPdf = PDFDocument.create();
        const mergedPdfPages = [];

        selectedAttachments.forEach((attachment) => {

            if (attachment.name.split('.').pop() === 'pdf') {
                // get the attachment content and add it to the merged pdf document
                mailbox.item.getAttachmentContentAsync(attachment.id, async (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        const pdfBytes = _base64ToArrayBuffer(result.value);
                        const pdf = PDFDocument.load(pdfBytes);
                        pdf.getPages().forEach((page) => {
                            mergedPdf.addPage(page);
                        });
                    }
                });
            }
        });
    };

    const handleSelectionChange = (selectedItems) => {
        setSelectedAttachments(selectedItems);
    };

    return (
        <div>
            {/* <span>{attachmentName}</span> */}
            <Button onClick={handleDownload}>Load Attachments from Email</Button>
            <div style={{maxHeight:"150px", overflow: 'auto'}}>
                <AttachmentTable attachments={attachments} onSelectionChange={handleSelectionChange} />
            </div>
            {attachments?.length > 0 ? <div>
                <Input
                    placeholder="Enter merged document name"
                    value={mergedDocumentName}
                    onChange={(e) => setMergedDocumentName(e.target.value)}
                />
                <Button onClick={handleMerge} disabled={selectedAttachments.length <= 1}>Merge</Button>
                <Button onClick={() => uploadEmailAttchmentAndEmailContentToSharepoint()} disabled={selectedAttachments.length === 0}>Upload</Button>
            </div> : null}
            <Toaster toasterId={toasterId} />
        </div>
    );
};

export default Attachment;
