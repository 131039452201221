export enum FacilityNumber{
'FN_9383',
'FN_2948417',
'FN_30176606',
'FN_30176699',
'Bridge',
'Facility_A',
'Facility_B',
'Facility_C',
'Facility_D',
'Facility_E',
'Facility_F',
'Facility_G',
'Facility_H',
'Facility_I',
'Facility_J',
'Facility_K',
'Facility_L',
'Facility_M',
'Facility_N',
'Facility_O',
'Facility_P',
'Facility_Q',
'Facility_R',
'Facility_S',
'Facility_T',
'Facility_U',
'Mezze',
'RDF01U',
'RDF02U',
'RDF03U',
'RDF04U',
'RDF05U',
'RDF06U',
'RDF07U',
'RDF08U',
'RDF09U',
'RDF10U',
'RDF11U',
'RDF12U',
'RDF13U',
'RDF14U',
'RDF15U',
'RDF16U',
'RDF17U',
'RDF18U',
'RDF19U',
'RDF26U',
'RDF27U',
'RDF28U',
'RDF29U',
'RDF30U',
'RDF31U',
'RDF32U',
'RDF33U',
'RDFB04',
'RDFB05',
'RDFB06',
'RDFB07',
'RDFB08',
'RDFB09',
'RDFB10',
'RDFB11',
'RDFB12',
'RDFB13',
'RDFB14',
'RDFB15',
'RDFB16',
'RDFB17',
'RDFB18',
'RDFB19',
'RDFB20',
'RDFB21',
'RDFB22',
'RDFB23',
'RDFB24',
'RDFB25',
'RDFB26',
'RDFC19',
'RDFC20',
'RDFC21',
'RDFC22',
'RDFC23',
'RDFC24',
'RDFC25',
'RDFC26',
'RDFC27',
'RDFC28',
'RDFC29',
'RDFC30',
'RDFC31',
'RDFC32',
'RDFC33',
'RDFC34',
'RDFC35',
'RDFC36',
'RDFC37',
'RDFC38',
'RDFC39',
'RDFC40',
'RDFC41',
'RDFC42',
'RDFC43',
'RDFC44',
'RDFC45',
'RDFC46',
'RDFC47',
'RDFC48',
'RDFC49',
'RDFC50',
'RDFC51',
'Revolver',
'Tranche_A',
'Tranche_B',
'Tranche_C',
'Tranche_D',
}