import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TableSelectionCell, useTableFeatures, useTableSelection } from '@fluentui/react-components';
import { on } from 'process';
import React, { useEffect, useState } from 'react';

interface Attachment {
    id: string;
    name: string;
}

interface AttachmentTableProps {
    attachments: Attachment[];
    onSelectionChange: (selectedAttachments: Attachment[]) => void;
}

const AttachmentTable: React.FC<AttachmentTableProps> = ({ attachments, onSelectionChange }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    useEffect(() => {
        const selectedAttachments = attachments.filter((attachment) => selectedRows.includes(attachment.id));
        onSelectionChange(selectedAttachments);
    }, [selectedRows]);
    const handleRowClick = (attachmentId: string) => {
        console.log({attachmentId});
        if (selectedRows.includes(attachmentId)) {
            setSelectedRows(selectedRows.filter((id) => id !== attachmentId));
        } else {
            setSelectedRows([...selectedRows, attachmentId]);
        }
    };

    const handleSendClick = () => {
        const selectedAttachments = attachments.filter((attachment) =>
            selectedRows.includes(attachment.id)
        );
        onSelectionChange(selectedAttachments);
    };

    const toggleAllRows = () => {
        if (selectedRows.length === attachments.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(attachments.map((attachment) => attachment.id));
        }
        handleSendClick();
    }

    return (
        <div>
            <Table aria-label="Attachment table">
                <TableHeader>
                    <TableRow>
                        <TableSelectionCell
                            checked={selectedRows.length === attachments.length}
                            onClick={toggleAllRows}
                            checkboxIndicator={{"aria-label": "Select all rows"}}
                        />
                        <TableHeaderCell>Name</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {attachments.map((attachment) => (
                        <TableRow
                            key={attachment.id}
                            aria-selected={selectedRows.includes(attachment.id)}
                            onClick={() => handleRowClick(attachment.id)}
                        >
                            <TableSelectionCell
                                checked={selectedRows.includes(attachment.id)}
                                checkboxIndicator={{ "aria-label": "Select row" }}
                            />
                            <TableCell>{attachment.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default AttachmentTable;
