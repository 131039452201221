export enum Bank {
    'Absa',
    'Standard Bank',
    'RMB',
    'Nedbank',
    'Investec',
    'Standerd Chartered',
    'Ninety One',
    'Liberty Finance',
    'Standard Bank IOM',
    'Amercian Express',
    'Goldman Sachs',
    'ING Bank',
    'DMTN',
    'JP Morgan',
    'Maquarie',
    'mBank',
    'National Bank of Australia',
    'Pekao',
}