import { Combobox, ComboboxProps, Field, Option } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { TagService } from '../../../services/tag.service';
interface Tenant {
    tenantID: number;
    tenantCode: string;
    propertyID: number;
    lesseeName: string;
    lessorAccountName: string;
    listOrTradingAsName: string;
}

interface TenantProps {
    propertyId: number;
    onTenantChange: (tenantId: number) => void;
    onPropertyTenantChange: (tenantId: number, propertyId: number) => void;
}
const Tenant = ({...tenantProps}: Partial<TenantProps>, props: Partial<ComboboxProps>) => {
    const [matchingOptions, setMatchingOptions] = React.useState([] as Tenant[]);
    const [tenants, setTenants] = React.useState([] as Tenant[]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [selectedTenant, setSelectedTenant] = React.useState({} as Tenant);
    const [selectedValue, setSelectedValue] = React.useState<string | undefined>(undefined);
    const tagService = new TagService();
    useEffect(() => {
        getTenants();
    }, []);
    useEffect(() => {
        if (tenantProps.propertyId !== undefined) {
            getTenants();
        }
    }, [tenantProps.propertyId]);
    useEffect(() => {
        if (searchTerm.length > 2) {
            getTenants();
        }
    }, [searchTerm]);

    const getTenants = async () => {
        try {
            const propertyId = tenantProps?.propertyId?.toString() ?? "";
            if (propertyId === "" && searchTerm === "") 
                return;
            if (propertyId === selectedTenant?.propertyID?.toString() && searchTerm === "")
                return;
            await tagService.getMDATenants(propertyId, searchTerm).then((data) => {
            setTenants(data.data);
            setSelectedValue(undefined);
            }, (error) => {
                console.error('Error fetching options:', error);
            });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.toString().trim();
        if (tenants.length > 0 && value.length > 2) {
            const tenantsOnly = tenants;
            const matches = tenantsOnly.filter((option) => {
                if (!isNaN(parseInt(value))) {
                    return option.tenantID.toString().includes(value.toLowerCase());
                } else {
                    return (option.tenantCode.toLowerCase().includes(value.toLowerCase()) || option.listOrTradingAsName.toLowerCase().includes(value.toLowerCase()));
                }
            }
            );
            setMatchingOptions(matches);
        } else {
            if (value.length > 2) {
                setSearchTerm(value);
            } else {
                setMatchingOptions([]);
            }
        }
        // setSearchTerm(value);
    };

    const handleSelection = (event, data) => {
        console.log(data, event);
        const selectedTenant = tenants.find(x => x.tenantID == +data.optionValue);
        if (selectedTenant) {
            tenantProps.onTenantChange(selectedTenant.tenantID);
            tenantProps.onPropertyTenantChange(selectedTenant.tenantID, selectedTenant.propertyID);
            setSelectedTenant(selectedTenant);
            setSearchTerm("");
            setSelectedValue(`${selectedTenant.listOrTradingAsName.trim()} - ${selectedTenant.tenantCode}`)
        }
    };

    return (
        <>
            <Field label="Tenant">
                <Combobox
                    // disabled={tenantProps.propertyId === undefined}
                    placeholder="Search for a tenant"
                    id="tenant"
                    onChange={handleSearchTermChange}
                    onOptionSelect={handleSelection}
                    value={selectedValue}
                    clearable
                    {...props}>
                    {matchingOptions.length == 0 && tenants.map((option) => (
                        <Option key={option.tenantID} value={option.tenantID.toString()} text={option.listOrTradingAsName.trim()+ " - "+option.tenantCode}>
                            {option.listOrTradingAsName.trim()} - {option.tenantCode}
                        </Option>
                    ))}
                    {matchingOptions.length > 0 && matchingOptions.map((option) => (
                        <Option key={option.tenantID} value={option.tenantID.toString()} text={option.listOrTradingAsName.trim()+ " - "+option.tenantCode}>
                            {option.listOrTradingAsName.trim()} - {option.tenantCode}
                        </Option>
                    ))}
                </Combobox>
            </Field>
        </>
    );
};

export default Tenant;
