export enum LegalDisposalsAndAquisitionsDocumentType {
'Accounts Issued',
'Accounts Received',
'Agreement for Disposal',
'Bank Guarantee(s)',
'Bank Release Document',
'Certificate(s) of Compliance',
'Certificates of Compliance',
'Confirmation of Property Registration',
'Consent to Transfer',
'Correspondence',
'Credit Notes Issued',
'Credit Notes Received',
'Deed of Transfer',
'Diagrams',
'Documents',
'Emails Received',
'Emails Sent',
'Invoices Issued',
'Invoices Received',
'IT Assets',
'Lease Agreement',
'Letters Received',
'Letters Sent',
'Material Defects Report',
'Meetings',
'Municipal Account',
'Non-GLA Assets',
'Notes',
'Offer to Purchase',
'Photographs',
'Plans',
'Proof of Payment',
'Property Annexure(s)',
'Property Diagram',
'Rates & Taxes Clearance Figures',
'Resolutions',
'Schedule of Employees',
'Servitudes',
'Statement of Account Issued',
'Statement of Account Received',
'Supplier Contracts',
'Suspensive Conditions Documentation',
'Tenant Age Analysis',
'Tenant Schedule',
'Title Deed',
'Transfer Duty',
'Utilities Documentation',
'Vendor Loan Security Documents',
'Verbal Correspondence'
}