import * as React from "react";
import Header from "./Header";
import HeroList, { HeroListItem } from "./HeroList";
import TextInsertion from "./TextInsertion";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler, makeStyles, SelectTabData, SelectTabEvent, Tab, TabList, TabValue } from "@fluentui/react-components";
import { Ribbon24Regular, LockOpen24Regular, DesignIdeas24Regular, Building16Regular, ChevronCircleUpRegular, ChevronCircleDownRegular, ChevronCircleUp16Filled, ChevronCircleDown20Filled, ChevronCircleUp20Filled } from "@fluentui/react-icons";
import { insertText } from "../taskpane";
import { Department } from "./Departments/department";
import DepartmentSelector from "./DepartmentSelector";
import { Tags } from "./Tags";
import Attachment from "./Attachment";
import { jwtDecode } from "jwt-decode";
import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { config, msalConfig } from "../../config";

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
  panels: {
    padding: "0 10px",
    "& th": {
      textAlign: "left",
      padding: "0 30px 0 0",
    }
  },
  accordion: {
    backgroundColor: "#eee",
    color: "#444",
    cursor: "pointer",
    padding: "18px",
    width: "100%",
    border: "none",
    textAlign: "left",
    outline: "none",
    fontSize: "15px",
    transition: "0.4s",
    borderBottom: "1px solid #ccc",
  },

  active: {
    ":hover": {
      backgroundColor: "#ccc"
    }
  },

  panelHidden: {
    padding: "0 18px",
    display: "none",
    backgroundColor: "white",
    overflow: "hidden",
    transition: "max-height 0.2s ease-out",
    maxHeight: "0",
  },
  panelShow: {
    padding: "0 18px",
    display: "block",
    backgroundColor: "white",
    overflow: "hidden",
    transition: "max-height 0.2s ease-in",
    maxHeight: "1000px",
  },
});

const App: React.FC<AppProps> = (props: AppProps) => {
  const styles = useStyles();
  const [selectedDepartment, setSelectedDepartment] = React.useState('');
  const [selectedDrive, setSelectedDrive] = React.useState('');
  const [selectedFolder, setSelectedFolder] = React.useState('');
  const [openItems, setOpenItems] = React.useState(["1"]);
  const [loadTags, setLoadTags] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState([]);
  const [showDepartment, setShowDepartment] = React.useState(false);
  const [showTags, setShowTags] = React.useState(false);
  const [showAttachments, setShowAttachments] = React.useState(false);
  const [sharepointFileIds, setSharepointFileIds] = React.useState([]);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = React.useState(null);
  // create a useffect to load the tags when the folder is selected
  React.useEffect(() => {
    if (selectedFolder != "") {
      setLoadTags(true);
    }
  }, [selectedFolder]);

  React.useEffect(() => {
    Office.onReady(async () => {
      const pca = new PublicClientApplication(msalConfig);
      console.log("MSAL Configuration:", msalConfig);
      console.log("Office is ready");
      await pca.initialize();
      console.log("MSAL Initialized");
      try {
        // aquire the token silently, if the user is not authenticated then show the login popup to the user
        const accounts = pca.getAllAccounts();
        const silentRequest = {
          scopes: ["User.Read"],
          account: accounts[0]
        };
        const response = await pca.acquireTokenSilent(silentRequest);
        console.log("Silent Response:", response);
        // store the token in the session storage
        sessionStorage.setItem('token', response.accessToken);
        sessionStorage.setItem('expiresOn', response.expiresOn.toDateString());
      } catch (error) {
        try {
          console.error("Silent Error:", error);
          await pca.loginPopup({
            scopes: ["User.Read"],
          }).then((response) => {
            console.log("Login Response:", response);
            // store the token in the session storage
            sessionStorage.setItem('token', response.accessToken);
            sessionStorage.setItem('expiresOn', response.expiresOn.toDateString());
          }).catch((error) => {
            console.error("Login Error:", error);
          });
        } catch (error) {
          const errorData = {
            errorMessage: error.errorCode,
            message: error.errorMessage,
            errorCode: error.stack
          };
          console.error(error);
        }
      }
    });
  }, []);

  const submitMetaData = (fileInfo: any) => {
    console.log(fileInfo);
    setFileInfo(fileInfo);
  }

  const showTabPanel = (tab: string) => {
    if (tab === "departments") {
      setShowDepartment(!showDepartment);
      setShowTags(false);
      setShowAttachments(false);
    } else if (tab === "tags") {
      setShowDepartment(false);
      setShowTags(!showTags);
      setShowAttachments(false);
    } else if (tab === "attachments") {
      setShowDepartment(false);
      setShowTags(false);
      setShowAttachments(!showAttachments);
    }
  }

  return (
    <div className={styles.root}>
      <Header logo="assets/redefine_logo_no_slogan.png" title={props.title} message="Welcome to ECM for Outlook" />
        <button className={styles.accordion} onClick={() => showTabPanel("departments")}>
        {showDepartment ? <ChevronCircleUp20Filled /> : <ChevronCircleDown20Filled />}Select Department</button>
        <div className={showDepartment ? styles.panelShow : styles.panelHidden}>
          <DepartmentSelector
            onDepartmentChange={setSelectedDepartment}
            onDriveChange={setSelectedDrive}
            onFolderChange={setSelectedFolder} />
        </div>
        <button className={`${styles.accordion} ${showTags ? styles.active : ""}`} onClick={() => showTabPanel("tags")}>
          {showTags ? <ChevronCircleUp20Filled /> : <ChevronCircleDown20Filled />}
          <span>Complete Document Tags</span>
          </button>
        <div style={{paddingBottom: 20}} className={showTags ? styles.panelShow : styles.panelHidden}>
          {loadTags ? (<Tags folderId={selectedFolder} uploadedFileInfo={fileInfo}/>) : null}
        </div>
        <button className={styles.accordion} onClick={() => showTabPanel("attachments")}>
        {showAttachments ? <ChevronCircleUp20Filled /> : <ChevronCircleDown20Filled />}Add Attachments</button>
        <div className={showAttachments ? styles.panelShow : styles.panelHidden}>
          <Attachment sharepointUploadResult={submitMetaData} folderId={selectedFolder} driveId={selectedDrive} departmentId={selectedDepartment} />
        </div>
      </div>
  );
};

export default App;
