import axios from 'axios';
import { config } from '../config';
import { Item } from '../models/item.model';
import { SharepointUploadResult } from '../models/sharepointFileUpload.model';

const environment = 'development';
// process.env.NODE_ENV || 'local';
const { endpoint } = config[environment];; // Replace with your configured URL

export const getDepartments = async () => {
    try {
        const response = await axios.get(`${endpoint}/ECM/Departments`);
        return response.data;
    } catch (error) {
        console.error('Error getting departments:', error);
        throw error;
    }
};

export const getDrive = async (selectedDepartmentId: string) => {
    try {
        const response = await axios.get(`${endpoint}/ECM/Drives?siteId=${selectedDepartmentId}`);
        return response.data;
    } catch (error) {
        console.error('Error getting drive:', error);
        throw error;
    }
};

export const getFolders = async (selectedDepartmentId: string, selectedDriveId: string) => {
    try {
        const response = await axios.get(`${endpoint}/ECM/Folder?siteId=${selectedDepartmentId}&driveId=${selectedDriveId}`);
        return response.data;
    } catch (error) {
        console.error('Error getting folders:', error);
        throw error;
    }
};

export const uploadSPfileFolder = async (file: File, siteId: string, driveId: string, folderId: string) => {
    try {
        const token = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true });
        const response = await axios.post(`${endpoint}/ECM/uploadSPfileFolder`, {
            file,
            siteId,
            driveId,
            folderId
        }, {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error uploading attachment:', error);
        throw error;
    }
}

export const uploadAttachmentToSharepoint = async (file: File, siteId: string, driveId: string, folderId: string) => {
    try {
        const response = await fetch(`https://graph.microsoft.com/beta/sites/${siteId}/drives/${driveId}/items/${folderId}:/${file.name}:/content`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token') || ''
            },
            body: JSON.stringify({
                item: {
                    '@microsoft.graph.conflictBehavior': 'rename',
                    name: file.name,
                }
            })
        });
        const data = await response.json();
        const uploadUrl = data.uploadUrl;
        const uploadResponse = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file
        });
        const uploadData = await uploadResponse.json();
        console.log(uploadData);
    } catch (error) {
        console.error('Error uploading attachment:', error);
        throw error;
    }
};

export const setTokenHeader = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const uploadFileMetaData = async (fileData: Item[]) => {
    try {
        const response = await axios.post(`${endpoint}/ECM/Items`, fileData);
        return response.data;
    } catch (error) {
        console.error('Error uploading file metadata:', error);
        throw error;
    }
}
