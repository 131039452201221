export enum LegalSpecialProjectsDocumentType {
'Accounts Issued',
'Accounts Received',
'Agreement(s)',
'Appointment Documents',
'Bank Guarantee(s)',
'Correspondence',
'Credit Notes Issued',
'Credit Notes Received',
'Diagrams',
'Documents',
'Documents Collected',
'Emails Received',
'Emails Sent',
'Findings',
'Invoices Issued',
'Invoices Received',
'Letters Received',
'Letters Sent',
'Meetings',
'Notes',
'Notes on Meeting',
'Other Evidence Collected',
'Photographs',
'Plans',
'Project Document',
'Proof of Payment',
'Qualifications',
'Recommendations',
'Resolution(s)',
'Resolutions',
'Statement of Account Issued',
'Statement of Account Received',
'Verbal Correspondence',
}