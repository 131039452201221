import { Combobox, ComboboxProps, Field, FieldProps, Option, makeStyles, shorthands } from '@fluentui/react-components';
import { on } from 'process';
import React, { useEffect } from 'react';
import { TagService } from '../../../services/tag.service';
interface Property {
    propertyID: number;
    propertyCode: string;
    propertyName: string;
    portfolioID: number;
    propertyTypeID: number;
    ownerEntityID: number;
}

interface PropertySearchProps {
    onPropertyChange: (property: Property) => void;
    propertyID?: any;
}

const useStyles = makeStyles({
    listbox: {
      maxHeight: "250px",
    },
    option: {
      height: "32px",
    },
  });

const PropertySearch = ({propertyID, onPropertyChange}: PropertySearchProps, props: Partial<ComboboxProps>) => {
    const [matchingOptions, setMatchingOptions] = React.useState([] as Property[]);
    const [properties, setProperties] = React.useState([] as Property[]);
    const [selectedProperty, setSelectedProperty] = React.useState('');
    const [value, setValue] = React.useState("");
    const tagService = new TagService();
    const styles = useStyles();
    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {
        if (propertyID) {
           fetchOptions();
        }
    }, [propertyID]);

    const fetchOptions = async () => {
        try {
            const pId = selectedProperty !== propertyID?.toString() ? propertyID?.toString() : "";
            tagService.getMDAProperties(pId).then((data) => {
                console.log(data);
                setProperties(data.data);
                if (propertyID) {
                    const property = data.data.find((p) => p.propertyID.toString() == propertyID);
                    if (property) {
                        setSelectedProperty(property.propertyID.toString());
                        setValue(property.propertyName.trimEnd()+" - "+property.propertyID);
                        onPropertyChange(property);
                    }
                }
            }, (error) => {
                console.error('Error fetching options:', error);
            });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };
    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.trim();
        setValue(value);
        if (!isNaN(parseInt(value)) && value.length >= 2) {
            const matches = properties.filter((option) => {
                return option.propertyID.toString().includes(value.toLowerCase());   
            }
            );
            setMatchingOptions(matches);
        } else if (isNaN(parseInt(value)) && value.length >= 2) {
            const matches = properties.filter((option) => {
                return option.propertyName.toLowerCase().includes(value.toLowerCase());
            });
            setMatchingOptions(matches);
        }
    };

    const handleSelection = (a: any) => {
        onPropertyChange(properties.find(x => x.propertyID == a.optionValue));
      };
    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        if (data) {
            console.log(ev, data);
            const property = properties.find(x => x.propertyID.toString() == data.optionValue);
            if (property) {
                setSelectedProperty(property.propertyID.toString());
                setValue(property.propertyName);
                onPropertyChange(property);
            }
        }
      };

    return (
        <>
            <Field label="Property" aria-autocomplete='none'>
                <Combobox
                    placeholder="Search for Property..."
                    id="property"
                    aria-autocomplete="none"
                    onChange={handleSearchTermChange}
                    onOptionSelect={onOptionSelect}
                    value={value}
                    clearable
                    selectedOptions={[selectedProperty]}
                    listbox={{ style: { maxHeight: "250px" }}}
                    {...props}>
                        {matchingOptions.length < 1 && properties?.map((option) => (
                            <Option key={option.propertyID} value={option.propertyID.toString()} text={option.propertyName.trimEnd()+" - "+option.propertyID}>
                                {option.propertyName.trimEnd()} - {option.propertyID}
                            </Option>
                        ))}
                        {matchingOptions.length > 0 && matchingOptions?.map((option) => (
                            <Option key={option.propertyID} value={option.propertyID.toString()} text={option.propertyName.trimEnd()+" - "+option.propertyID}>
                                {option.propertyName.trimEnd()} - {option.propertyID}
                            </Option>
                        ))}
                    </Combobox>
            </Field>
        </>
    );
};

export default PropertySearch;
