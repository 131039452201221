export enum Period {
    'Bi Weekly',
    'FY',
    'HY',
    'Monthly',
    'Q1',
    'Q2',
    'Q3',
    'Q4',
    'Quarterly',
}