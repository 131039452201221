import React, { useEffect, useState } from "react";
import { Default as DatePicker } from "./TagDynamicFields/DatePicker";
import { Default as Input } from "./TagDynamicFields/Input";
import { Default as Select } from "./TagDynamicFields/Select";
import { Default as TextArea } from "./TagDynamicFields/TextArea";
import { IClassificationTag } from "../../models/classification.model";
interface TagClassificationsProps {
  formData: any;
  onTagChange?: (tag: any) => void;
}
const TagClassifications = ({ formData, onTagChange }: TagClassificationsProps) => {
  const [state, setState] = useState({} as IClassificationTag);
  
  useEffect(() => {
    // check to make sure the state is not empty
    // if not empty, call the onTagChange function
    if (Object.keys(state).length !== 0)
      onTagChange(state);
  }, [state]);

  const formValueChange = (e) => {
    // e.preventDefault();
    // get the field name and value from event
    const fieldName = e.target ? e.target.name : e.name;
    const fieldValue = e.target ? e.target.value : e.value;
    console.log(fieldName, fieldValue);
    const tag = { ...state, [fieldName]: fieldValue };
    // update the state accordingly to the field name and value keep the rest as is
    //setState(tag);
    onTagChange(tag);
  }

  const onDateSelect = (date, fieldName) => {
    const tag = { ...state, [fieldName]: date.toDateString() };
    // convert date to string and update the state accordingly to the field name and value keep the rest as is
    // setState(tag);
    onTagChange(tag);
  }


  return (
    <form style={{paddingBottom: 2}}>
      {formData.map((field, _index) => {
        switch (field.type) {
          case "Text":
            return <TextArea textAreaProps={
              {
                placeholder: "Enter "+field.classificationName+" here...",
                name: field.classificationName,
                onChange: (e) => formValueChange(e)
              }
            } fieldProps={
              {
                label: field.classificationName,
                validationState: field.requiredTag === "Yes" ? "error" : "none",
              }
            } key={field.classificationID} />;
          case "Number":
            return <Input inputProps={
              {
                placeholder: "Enter "+field.classificationName+" here...",
                name: field.classificationName,
                onChange: (e) => formValueChange(e),
                type: "number",
              }
            } fieldProps={
              {
                label: field.classificationName,
                validationMessage: "Please enter a valid number"
              }
            } key={field.classificationID}/>;
            case "Input":
            return <Input inputProps={
              {
                placeholder: "Enter "+field.classificationName+" here...",
                name: field.classificationName,
                onChange: (e) => formValueChange(e),
                type: "text",
              }
            } fieldProps={
              {
                label: field.classificationName,
              }
            } key={field.classificationID}/>;
          // Add case for select
          case "Select":
            return <Select selectProps={
              {
                // placeholder: "Select "+field.classificationName+"...",
                name: field.classificationName,
                onChange: (e) => formValueChange(e),
              }
            } fieldProps={
              {
                label: field.classificationName,
              }
            } key={field.classificationID}/>;
          // Add case for textarea
          case "Textarea":
            return <TextArea textAreaProps={
              {
                placeholder: "Enter "+field.classificationName+" here...",
                name: field.classificationName,
                onChange: (e) => formValueChange(e),
              }
            } fieldProps={
              {
                label: field.classificationName,
              }
            } key={field.classificationID}/>;
          // Add case for date
          case "Date":
            return <DatePicker datePickerProps={
              {
                placeholder: "Pick "+field.classificationName+"...",
                name: field.classificationName,
                onSelectDate: (e) => onDateSelect(e, field.classificationName),
              }
            } fieldProps={
              {
                label: field.classificationName,
              }
            } key={field.classificationID}/>;
          default:
            return null;
        }
      })}
    </form>
  );
};

export default TagClassifications;
