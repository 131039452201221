export enum LegalContractTypes{
'3rd Party Contract',
'Acknowledgement of Debt',
'Acquisition Agreements',
'Advertising Agreement ',
'Advisory Agreements',
'Agency Agreement',
'Agreement of Loss',
'Application Contracts',
'Arbitration Agreement',
'Asset Management Agreement',
'Cancellation / Breach of Contract',
'Cancellation Agreements',
'Cession Agreement',
'Cleaning contract',
'Co-Investors Agreements',
'Co-ownership Agreement',
'Court Application Contract', 
'Custody Agreement',
'Development Agreements',
'Development Management Agreement', 
'Discharge Agreement',
'Disposal Agreements',
'Donation Agreements',
'Employer Contract',
'Employment Contract',
'EPC Contract',
'Exchange Agreement',
'Exclusivity Agreement',
'Exhibition Contract',
'Exit Agreement',
'Facility Agreement', 
'Facilty Loan Agreements', 
'Gardens Contract',
'Guarantee Agreement', 
'Health & Safety Contract',
'Insurannce Contract', 
'Investment Agreements',
'IRO contract',
'ISDA Agreement',
'JBCC Contract', 
'Landlords Agreements',
'Lease Agreements',
'Liability Contract',
'Loan Agreements',
'Maintenance Contract',
'Management Agreement',
'Marketing Contract',
'Negligance Contract',
'Non-disclosure Agreements',
'Offer to Purchase',
'Online System Contract',
'Operational Management Agreements',
'OTP Agreement', 
'Performance Contract',
'Performance Management Agreement',  
'Promotional Request Contract',
'Property Management Agreement ',
'Property Portfolio Agreements',
'Redefine Properties Contract',
'Reimbursement Agreement',
'Renewal / Exstention Contract',
'Restatement Agreement', 
'Retail Agreements',
'Revised Agreement', 
'Revival Agreements',
'Sale Agreements',
'Sales Contract',
'Security Agreement',
'Service Agreements',
'Settlement Agreements',
'Share Purchase Agreements',
'Shareholders Agreement',
'Sponsorship Agreements',
'SPS Agreement',
'Sub Agreement',
'Subcontracting',
'Subscription Agreements',
'Supplier Contract',
'Suspension Agreements',
'Termination Agreements',
'Trade Agreements',
'Transfer Agreements',
'Wasteman Contract' 
}