import axios from 'axios';
import { config, msalConfig } from '../config';
import { AuthenticationResult, PublicClientApplication, InteractionRequiredAuthError} from "@azure/msal-browser";
const environment = 'development';
export class TagService {
    graphClient: any;
    msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);
    account: any;
    // setup a constructor to initialize the class
    constructor() { 
    }

    async initialize() {
        try {
            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length > 0) {
            this.account = accounts[0];
            } else {
            const loginResponse: AuthenticationResult = await this.msalInstance.loginPopup();
            this.account = loginResponse.account;
            }
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                const loginResponse: AuthenticationResult = await this.msalInstance.loginPopup();
                this.account = loginResponse.account;
            }
        }
    }

    async getToken() {
        const tokenRequest = {
            scopes: ["User.Read"],
        };
        await this.msalInstance.initialize();
        const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    }
    async getMDAProperties(propertyId?: string) {
        try {
            let url = `${config[environment].endpoint}/MDA/Properties`;
            if (propertyId?.length > 0) {
                url += `?PropertyID=${propertyId}`;
            }
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetchingproperty options:', error);
        }
    }

    async getMDAPropertyTypes(propertyTypeId: number): Promise<any> {
        try {
            const response = await axios.get(`${config[environment].endpoint}/MDA/PropertyTypes?propertyTypeId=${propertyTypeId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching property type options:', error);
        }
    }

    async getMDAPortfolio(portfolioID: number) {
        try {
            const response = await axios.get(`${config[environment].endpoint}/MDA/Portfolio?PortfolioID=${portfolioID}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching portfolio options:', error);
        }
    }

    async getMDATenants(propertyID: string, searchTerm: string) {
        try {
            let url = `${config[environment].endpoint}/MDA/Tenants?PropertyID=${propertyID}`;
            if (searchTerm) {
                url += `&Search=${searchTerm}`;
            }
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching tenant options:', error);
        }
    }

    async prepareAttachmentForUpload(attachment: any, content: any): Promise<{ fileName: string, contentType: string, contentLength: number, content: ArrayBuffer }> {
        return new Promise((resolve, reject) => {
            if (!attachment || !content) {
                reject('Attachment or content is empty');
            }

            const file = this._base64ToArrayBuffer(content.content);
            const fileName = attachment.name;
            const contentType = attachment.contentType;
            const contentLength = file.byteLength;

            const body = {
                fileName,
                contentType,
                contentLength,
                content: file
            };

            resolve(body);
        });
    }

    async uploadAttachmentToSharepoint(fileAttachment: any, content: any, siteId: string, driveId: string, folderId: string) {
        try {
            // check if the token is expired
            if (this.isTokenExpired(sessionStorage.getItem('token'))) {
                await this.refreshToken();
            }
            const body: { fileName: string, contentType: string, contentLength: number, content: ArrayBuffer } = await this.prepareAttachmentForUpload(fileAttachment, content);
            if (!body) {
                return null;
            }

            // make a fetch request to upload the file
            const response = await fetch(`https://graph.microsoft.com/beta/sites/${siteId}/drives/${driveId}/items/${folderId}:/${body.fileName}:/content?@microsoft.graph.conflictBehavior=rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + 
                    //this.getAccessToken().AccessToken
                    sessionStorage.getItem('token') || ''
                },
                body: body.content
            });
            console.log({response});
            if (!response.ok) {
                // return error
                return null;
            }

            return await response.json();

        } catch (error) {
            // if token is expired, refresh the token and try again
            if (error.status === 401) {
                await this.refreshToken();
                return this.uploadAttachmentToSharepoint(fileAttachment, content, siteId, driveId, folderId);
            }
        }
    }

    isTokenExpired(token: string): boolean {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        const now = Math.floor(Date.now() / 1000);
        return now > expiry;
    }

    async refreshToken() {
        try {
            await this.msalInstance.initialize();
            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                this.account = accounts[0];
                const silentRequest = {
                    scopes: ["User.Read"],
                    account: this.account
                };
                const response = await this.msalInstance.acquireTokenSilent(silentRequest);
                // update the token in the session storage
                sessionStorage.setItem('token', response.accessToken);
            } else {
                const loginResponse: AuthenticationResult = await this.msalInstance.loginPopup();
                this.account = loginResponse.account;

                // update the token in the session storage
                sessionStorage.setItem('token', loginResponse.accessToken);
            }

        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    }

    async uploadEmailToSharepoint(fileAttachment: any, siteId: string, driveId: string, folderId: string) {
        try {
            // check if the token is expired
            if (this.isTokenExpired(sessionStorage.getItem('token'))) {
                await this.refreshToken();
            }
            if (!fileAttachment.name.endsWith('.html')) {
                fileAttachment.name += '.html';  // Add the .html extension if it's missing
            }
            const response = await fetch(`https://graph.microsoft.com/beta/sites/${siteId}/drives/${driveId}/items/${folderId}:/${fileAttachment.name}:/content?@microsoft.graph.conflictBehavior=rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'text/html',
                    'Authorization': 'Bearer ' + 
                    //this.getAccessToken().AccessToken
                    sessionStorage.getItem('token') || ''
                },
                body: fileAttachment.content
            });

            if (!response.ok) {
                // return error
                return null;
            }

            return await response.json();

        } catch (error) {
            return error;
        }
    }

    async rollbackSharepointUpload(departmentId: string, driveId: string, folderId: string, fileId: string) {
        try {
            const response = await fetch(`https://graph.microsoft.com/beta/sites/${departmentId}/drives/${driveId}/items/${folderId}/items/${fileId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + 
                    //this.getAccessToken().AccessToken
                    sessionStorage.getItem('token') || ''
                }
            });
            console.log({response});
            if (!response.ok) {
                // return error
                return response;
            }

            return await response.json();

        } catch (error) {
            console.error('Error rolling back attachment:', error);
            return error;
    }
}

    async getTags(folderId: string) {
        try {
            const response = await axios.get(`${config[environment].endpoint}/ECM/ContainerClassificationTags?ContainerID=${folderId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    }

    _base64ToArrayBuffer(base64: string): ArrayBuffer {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
        }
    
    // getAccessToken = () => 
    //     {
    //         return {
    //             "TokenResultCode": 0,
    //             "AccessToken": "eyJ0eXAiOiJKV1QiLCJub25jZSI6IkhJSmNydnAtT2R0OTVXSWxpa1RpUkZ6aW0xSDd3T2FSSkV4OU5YZHEzbEEiLCJhbGciOiJSUzI1NiIsIng1dCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCIsImtpZCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCJ9.eyJhdWQiOiJodHRwczovL3N1YnN0cmF0ZS5vZmZpY2UuY29tL3NlYXJjaCIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0Lzc5ZWVlZWJiLWFmNDUtNDRjMS1hZTI4LWI2YjQ4OWZhNzZjNy8iLCJpYXQiOjE3Mjk0OTQ0NzgsIm5iZiI6MTcyOTQ5NDQ3OCwiZXhwIjoxNzI5NDk5OTk3LCJhY2N0IjowLCJhY3IiOiIxIiwiYWlvIjoiQVZRQXEvOFlBQUFBaXA3cDAwUlR5bHJYVmhmMTNVUVF1cnFxS01ldk1OZ0ZyS0NSQ0N1MTB6bEhnSHVDSzcveHUvZEFYYUVWTVpWNzM5YWZBTFNGd3NVS3VBOWZ6V3NLeDh3ai9QNlA1a01telpKd3VxdFh1YTA9IiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6IjAwMDAwMDAyLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsImFwcGlkYWNyIjoiMiIsImF1dGhfdGltZSI6MTcyOTQ4ODIyNSwiZ2l2ZW5fbmFtZSI6IlJlZGVmaW5lX1dvcmtmbG93IiwiaWR0eXAiOiJ1c2VyIiwiaXBhZGRyIjoiMTAyLjIxOS4yMjQuMTQiLCJuYW1lIjoiUmVkZWZpbmVfV29ya2Zsb3ciLCJvaWQiOiI5MTJlOGIzZi05MzA5LTRiZTEtOGFlNS01YWUzOGJiMjUyZmIiLCJwdWlkIjoiMTAwM0JGRkRBRjgzN0IwRSIsInJoIjoiMC5BUjhBdS03dWVVV3Z3VVN1S0xhMGlmcDJ4MWVIcUdhTUpYSk1pVHctaS0xTmFKa2ZBT2cuIiwic2NwIjoiU3Vic3RyYXRlU2VhcmNoLUludGVybmFsLlJlYWRXcml0ZSIsInNpZ25pbl9zdGF0ZSI6WyJpbmtub3dubnR3ayJdLCJzdWIiOiI2aXVwaWxTaHEyRGxvc1drc2F6elhBZ19VUEktd2xTQWVpb1lUZW9La0ZVIiwidGlkIjoiNzllZWVlYmItYWY0NS00NGMxLWFlMjgtYjZiNDg5ZmE3NmM3IiwidW5pcXVlX25hbWUiOiJyZWRlZmluZUByZWRlZmluZS5jby56YSIsInVwbiI6InJlZGVmaW5lQHJlZGVmaW5lLmNvLnphIiwidXRpIjoiRmZjaE15VC1vVTJ3S094aWwxQUdBQSIsInZlciI6IjEuMCIsInhtc19pZHJlbCI6IjEgMjAifQ.ZGg1gx4K3SIcvXMHLWCE9eVYO59RmQsnpgAMqrKBCmJsVJBth4ySf8z4oA3gyMg_R4S-lwDbMmC1xSs9v4hpip3_A4EjcPEX4KkDLcedYT5OXbfdkdJyDpgD_zusQpaZsF2hLIt5aKpvyyyFnO-56oSuH753kq2gevLo5EI78Mj3NLUJMdezb-i7jK8EoY03h5YPNZKSwSAR3NktUrShSm5fmm6GIF7Y8kmIglFeWzzobdJ_YGX0GQO6Wk9riXuZR4mP_hODZjAzfdriy2UZcqbPAfT83CXAA9021DhaMoBeJvw5e4UlkivTkzsXD3DhlxE4bUSGSNytxHDQdqfUgg",
    //             "AccessTokenExpiry": "2024-10-21T08:39:56.788Z",
    //             "ExpiresIn": 5217,
    //             "SubErrorCode": null
    //         }
    //     }



}