import { Field, Combobox, ComboboxProps, Option } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';

interface CashBook {
    cashBookID: string;
    cashBookName: string;
}

interface CashBookProps {
    cashBookID: string;
    onCashBookChange: (cashBookId: string) => void;
}

const CashBook = ({...cashbookProps}: Partial<CashBookProps>, props: Partial<ComboboxProps>) => {
    const [cashBooks, setCashBooks] = useState<CashBook[]>([]);
    const [matchingOptions, setMatchingOptions] = React.useState([] as CashBook[]);
    useEffect(() => {
        if (cashbookProps.cashBookID !== undefined) {
            fetchCashBooks();
        }
    }, [cashbookProps.cashBookID]);

    const fetchCashBooks = async () => {
        try {
            const response = await fetch('`https://localhost:44392/api/MDA/CashBooks`');
            const data = await response.json();
            setCashBooks(data.data);
        } catch (error) {
            console.error('Error fetching cash books:', error);
        }
    };

    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.trim();
        const matches = matchingOptions.filter((option) => {
            if (!isNaN(parseInt(value))) {
                return option.cashBookID.toString().includes(value.toLowerCase());
            } else {
                return option.cashBookName.toLowerCase().includes(value.toLowerCase());
            }
        });
        setCashBooks(matches);
    };

    const handleSelection = (event, data) => {
        console.log(data, event);
        cashbookProps.onCashBookChange(data.optionValue);
    }

    return (
        <>
            <Field label="Cash Book">
                <Combobox
                    disabled={cashbookProps.cashBookID === undefined}
                    placeholder="Search for a cash book"
                    id="cashBook"
                    onChange={handleSearchTermChange}
                    onOptionSelect={handleSelection}
                    {...props}>
                    {matchingOptions.length == 0 && cashBooks.map((option) => (
                        <Option key={option.cashBookID} value={option.cashBookID} text={option.cashBookName}>
                            {option.cashBookName}
                        </Option>
                    ))}
                    {matchingOptions.length > 0 && matchingOptions.map((option) => (
                        <Option key={option.cashBookID} value={option.cashBookID} text={option.cashBookName}>
                            {option.cashBookName}
                        </Option>
                    ))}
                </Combobox>
            </Field>
        </>
    );
};

export default CashBook;
