import { ComboboxProps, Field, Combobox, Option } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { TagService } from "../../../services/tag.service";

interface PropertyType {
    propertyTypeID: number;
    propertyTypeDescription: string;
}

interface PropertyTypeProps {
    propertyTypeID: number;
    onPropertyTypeChange: (propertyTypeId: string) => void;
}

const PropertyType = ({...propertyTypeProps}: Partial<PropertyTypeProps>, props: Partial<ComboboxProps>) => {
    const [matchingOptions, setMatchingOptions] = React.useState([] as PropertyType[]);
    const [propertyTypes, setPropertyTypes] = React.useState([] as PropertyType[]);
    const [selectedPropertyType, setSelectedPropertyType] = React.useState('');
    const [value, setValue] = React.useState("");
    const tagService = new TagService();
    useEffect(() => {
        if (propertyTypeProps.propertyTypeID !== undefined || selectedPropertyType !== propertyTypeProps.propertyTypeID.toString()) {
            getPropertyTypes();
        }
    }, [propertyTypeProps.propertyTypeID]);

    // useEffect(() => {
    //     // the property types are fetched, set the default value
    //     if (propertyTypes.length > 0) {
    //         // set the default value to property type id
    //         let id = propertyTypeProps.propertyTypeID;
    //         let propertyType = propertyTypes.find((p) => p.propertyTypeID == id);
    //         if (propertyType) {
    //             propertyTypeProps.onPropertyTypeChange(propertyType.propertyTypeID.toString());
    //         }
    //     }
    // }, [propertyTypes]);

    const getPropertyTypes = async () => {
        try {
            // let url = `https://localhost:44392/api/MDA/PropertyTypes?propertyTypeId=${propertyTypeProps.propertyTypeID}`;
            tagService.getMDAPropertyTypes(propertyTypeProps.propertyTypeID).then((data) => {
            // const response = await fetch(url);
            // const data = await response.json();
            console.log(data);
            setPropertyTypes(data.data);
            const propertyType = data.data.find((p) => p.propertyTypeID == propertyTypeProps.propertyTypeID);
            if (propertyType) {
                setSelectedPropertyType(propertyType.propertyTypeID.toString());
                setValue(propertyType.propertyTypeDescription);
            }
            }, (error) => {
                console.error('Error fetching options:', error);
            });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.trim();
        const matches = matchingOptions.filter((option) => {
            if (!isNaN(parseInt(value))) {
                return option.propertyTypeID.toString().includes(value.toLowerCase());
            } else {
                return option.propertyTypeDescription.toLowerCase().includes(value.toLowerCase());
            }
        });
        setMatchingOptions(matches);
    }

    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        console.log(ev, data);
        propertyTypeProps.onPropertyTypeChange(data.optionValue);
      };

      const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setValue(ev.target.value);
      };

    return (
        <>
            <Field label="Property Type">
                <Combobox
                    placeholder="Search for a property type"
                    id="propertyType"
                    aria-autocomplete="none"
                    onOptionSelect={onOptionSelect}
                    value={value}
                    selectedOptions={[selectedPropertyType]}
                    {...props}>
                        {matchingOptions.length == 0 && propertyTypes.map((option) => (
                            <Option key={option.propertyTypeID} value={option.propertyTypeID.toString()} text={option.propertyTypeDescription}>
                                {option.propertyTypeDescription}
                            </Option>
                        ))}
                        {matchingOptions.length > 0 && matchingOptions.map((option) => (
                            <Option key={option.propertyTypeID} value={option.propertyTypeID.toString()} text={option.propertyTypeDescription}>
                                {option.propertyTypeDescription}
                            </Option>
                        ))}
                    </Combobox>
            </Field>
        </>
    );
}

export default PropertyType;