export enum Year {
    '02024' = 2024,
    '02025' = 2025,
    '02026' = 2026,
    '02027' = 2027,
    '02028' = 2028,
    '02029' = 2029,
    '02030' = 2030,
    '02031' = 2031,
    '02032' = 2032,
    '02033' = 2033,
    '02034' = 2034,
    '02035' = 2035,
    '02036' = 2036,
    '02037' = 2037,
    '02038' = 2038,
    '02039' = 2039,
    '02040' = 2040,
}