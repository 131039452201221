export enum FinanceDocumentType{
'Wear and tear schedules',
'CFC',
'Transfer pricing',
'Leanership allowance',
'Capital gains tax',
'Debt raising fees',
'Donations',
'FX',
'PMP',
'Deferred tax',
'Tenant installation and lease commission',
'Share based payments',
'Master file',
'Local file',
'Tenants with credit balance',
'Roll and clear',
'Monthly VAT returns',
'VAT recon',
'VAT breakdown',
'Distributable income',
'VAT apportionment',
'Tax computation',
'SARS return',
'SARS guide',
'SARS supporting documents',
'Tax status workings',
'Tax pack',
'Board report',
'Monthly deck',
'Monthly commentary',
'Management accounts',
'Management accounts - Phased monthly',
'Monthly financial pack',
'Outstanding tax issues',
'Slide support',
'Financial statement support',
'Financial results support',
'Journal workings',
'Journals',
'Pivot',
'Balance sheet recons',
'Adjustment account: Superceded',
'Adjustment account: Prelim',
'Adjustment account: Final',
'Adjustment account: Support',
'Adjustment account: Correspondance',
'Adjustment account: Refunds',
'MDA reports',
'Other',
}