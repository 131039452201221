import { Combobox, ComboboxProps, Field, makeStyles, shorthands, useId, Option } from '@fluentui/react-components';
import React, { useState, useEffect } from 'react';
import { TagService } from '../../../services/tag.service';
interface Portfolios {
    portfolioID: number;
    portfolioName: string;
    portfolioManager: string;
    telephoneNos: string;
    faxNos: string;
    emailNos: string;
}

interface PortfolioProps {
    portfolioID: number;
    onPortfolioChange: (portfolioId: string) => void;
}
const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});
const AutocompleteSearch = ({...portfolioProps}: Partial<PortfolioProps>,props: Partial<ComboboxProps>) => {
  const comboId = useId("combo-default");
  const [options, setOptions] = useState([] as Portfolios[]);
  const [matchingOptions, setMatchingOptions] = React.useState([...options]);
  const [portfolios, setPortfolios] = React.useState([] as Portfolios[]);
  const [selectedPortfolio, setSelectedPortfolio] = React.useState('');
  const [value, setValue] = React.useState("");
  const styles = useStyles();
  useEffect(() => {
    const tagService = new TagService();
    const fetchOptions = async () => {
      try {
        tagService.getMDAPortfolio(portfolioProps.portfolioID).then((data) => {
        console.log(data);
        setOptions(data.data);
        setPortfolios(data.data);
        const portfolio = data.data.find((p) => p.portfolioID == portfolioProps.portfolioID);
        if (portfolio) {
          setSelectedPortfolio(portfolio.portfolioID.toString());
          portfolioProps.onPortfolioChange(portfolio.portfolioID.toString());
          setValue(portfolio.portfolioName+"-"+portfolio.portfolioID);
        }
        }, (error) => {
          console.error('Error fetching options:', error);
        });
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    if (portfolioProps.portfolioID !== undefined || selectedPortfolio !== portfolioProps.portfolioID.toString()) 
      fetchOptions();
  }, [portfolioProps.portfolioID]);

  useEffect(() => {
    if (portfolios.length > 0) {
      let id = portfolioProps.portfolioID;
      let portfolio = portfolios.find((p) => p.portfolioID == id);
      if (portfolio) {
        setSelectedPortfolio(portfolio.portfolioID.toString());
        portfolioProps.onPortfolioChange(portfolio.portfolioID.toString());
      }
    }
  }, [portfolios]);


  const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
    const value = event.target.value.trim();
    const matches = options.filter((option) => {
      if (!isNaN(parseInt(value))) {
        return option.portfolioID.toString().includes(value.toLowerCase());
      } else {
        return option.portfolioName.toLowerCase().includes(value.toLowerCase());
      }
    }
    );
    setMatchingOptions(matches);
  };


  const handleSelection = (event, data) => {
    console.log(data, event);
    portfolioProps.onPortfolioChange(data.optionValue);
  }

  return (
    <Field label="Portfolio">
        <Combobox
          placeholder="Search for a portfolio..."
          value={value}
          onOptionSelect={handleSelection}
          selectedOptions={[selectedPortfolio]}
          {...props}
        >
          {matchingOptions.length == 0 && portfolios.map((option) => (
            <Option key={option.portfolioID} value={option.portfolioID.toString()} text={option.portfolioName.trim()+"-"+option.portfolioID}>
              {option.portfolioName}
            </Option>
          ))}
          {matchingOptions.length > 0 && matchingOptions.map((option) => (
            <Option key={option.portfolioID} value={option.portfolioID.toString()} text={option.portfolioName.trim()+"-"+option.portfolioID}>
              {option.portfolioName}
            </Option>
          ))}
        </Combobox>
    </Field>
  );
};

export default AutocompleteSearch;
