import * as React from "react";
import {
  makeStyles,
  shorthands,
  useId,
  Input,
  Field,
} from "@fluentui/react-components";
import type { FieldProps, InputProps } from "@fluentui/react-components";
import { useState } from "react";
import { Mail12Filled, PersonRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
});

interface Input {
    inputProps: InputProps;
    fieldProps: FieldProps;
}

export const Default = (props: Input) => {
  const [inputValue, setInputValue] = useState('');
  const inputId = useId("input");
  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data) => {
    setInputValue(event.target.value);
    if (props.inputProps.onChange) {
      props.inputProps.onChange(event, data);
    }
  };
  // Function to handle default value setting and triggering onChange
  const setDefaultValueAndNotify = (value: string) => {
    setInputValue(value);
    if (props.inputProps.onChange) {
      // Create a synthetic event to match the expected onChange signature
      const syntheticEvent = {
        target: {
          value: value,
          name: props.inputProps.name
        }
      } as any
      // React.ChangeEvent<HTMLInputElement>;
      
      props.inputProps.onChange(syntheticEvent, {
        value,
        // Include any other necessary data properties
      });
    }
  };
  React.useEffect(() => {
    if (inputValue.length > 0) {
      if ((props.fieldProps.label === "EmailTo") || 
      (props.fieldProps.label === "EmailCC") || 
      (props.fieldProps.label === "EmailFrom") || 
      (props.fieldProps.label === "EmailSubject")) {
        setDefaultValueAndNotify(inputValue);
      }
    }
  }, [inputValue]);
  React.useEffect(() => {
  if (props.fieldProps.label === "EmailTo") {
    // get the email address of recipients
    const emailTo = Office.context.mailbox.item.to;
    // set default value to the email address of the recipients, if there are multiple recipients, separate them with a semicolon
    // props.inputProps.value = emailTo.map((email) => email.emailAddress).join(";");
    // setDefaultValueAndNotify(props.inputProps.value);
    setInputValue(emailTo.map((email) => email.emailAddress).join(";"));
  }

  if (props.fieldProps.label === "EmailCC") {
    // get the email address of the cc
    const emailCC = Office.context.mailbox.item.cc;
    // set default value to the email address of the cc, if there are multiple cc, separate them with a semicolon
    // props.inputProps.value = emailCC.map((email) => email.emailAddress).join(";");
    // setDefaultValueAndNotify(props.inputProps.value);
    setInputValue(emailCC.map((email) => email.emailAddress).join(";"));
  }

  if (props.fieldProps.label === "EmailFrom") {
    // get the email address of the sender
    const emailFrom = Office.context.mailbox.item.from;
    // trigger the on change event and set the value to the email address of the sender
    props.inputProps.value = emailFrom.emailAddress;
    setInputValue(emailFrom.emailAddress);
  }

  if (props.fieldProps.label === "EmailSubject") {
    // get the email subject
    const emailSubject = Office.context.mailbox.item.subject;
    // set default value to the email subject
    // props.inputProps.value = emailSubject;
    // setDefaultValueAndNotify(props.inputProps.value);
    setInputValue(emailSubject);
  }
}, [props.fieldProps.label]);

  return (
    <Field
    label={props.fieldProps.label}>
    <Input id={inputId} 
    contentBefore={props.fieldProps.label === "JournalNumber" ? 'JNL_' : ''}
    {...props.inputProps} 
    value={inputValue} 
    onChange={handleChange} 
    key={props.fieldProps.key} />
    </Field>
  );
};