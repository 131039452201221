export enum FundingDocuments {
'APS',
'Common Terms',
'Subscription agreement',
'Facility Agreement',
'Vendor Loan Agreement',
'Resolutions',
'Term Sheet',
'ISDA',
'Security Cession',
'Ad-hoc Letter',
'Formalities Certificate',
'Arranging Fee',
'Guarantee',
'Utilisation request',
'Credit Support Annexure',
'Release letter',
'SARB approval',
'Directors Disclosure',
'Covenant relaxation',
'SPV Indemnity agreement',
'Signatory update',
'Strate',
'Extract of AGM Minutes',
'Engagement letter',
'Trade Confirmation',
'Termination Confirmation'
}