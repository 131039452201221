export enum DocumentDescriptionPrefix {
    'Broker Incentives',
    'Brokers',
    'Credit Control Correspondence',
    'Credit Control Reports',
    'Deals Tracking',
    'DOA',
    'Head Lease',
    'NonGLA',
    'P.C Memos',
    'Policies',
    'Procedures',
    'Property Profiles',
    'Property Reports',
    'Templates'
}