import { Dropdown, Option } from "@fluentui/react-components";
import { useState, useEffect } from "react";
import { getDrive } from "../../../services/endpoint";
import React from "react";
import type { DropdownProps } from "@fluentui/react-components";

interface ResponseData {
    id: string;
    name: string;
    webUrl: string;
}

interface DriveProps {
    departmentId?: string;
    onDriveChange?: (drive: string) => void;
}
const Drive = ({
    departmentId,
    onDriveChange,
}: DriveProps, props: Partial<DropdownProps>) => {
    const [drives, setDrives] = useState([] as ResponseData[]);
    const [selectedDrive, setSelectedDrive] = useState({} as ResponseData);
    const [selectedOptions, setSelectedOptions] = useState([] as string[]);
    useEffect(() => {

        const fetchData = async () => {
            setSelectedDrive({} as ResponseData);
            const drive = await getDrive(departmentId);
            setDrives(drive.data);
        };
        if (departmentId)
            fetchData();
    }, [departmentId]);

    const onOptionSelect : (typeof props)["onOptionSelect"] = (ev, data) => {
        console.log(ev, data);
        setSelectedDrive(drives.find((d) => d.id === data.optionValue));
        setSelectedOptions(data.selectedOptions);
        onDriveChange(data.optionValue);
    }
    return (
        <div style={{width: '100%'}}>
            <label id="drp">Drive:</label>
            <Dropdown
                aria-labelledby="drp"
                placeholder="Select Drive"
                style={{ width: "100%" }}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
            >
                {drives.length > 0
                    ? drives.map((drive) => (
                          <Option
                              key={drive.id}
                            text={drive.name}
                              value={drive.id}
                          >
                              {drive.name}
                          </Option>
                      ))
                    : null}
            </Dropdown>
        </div>
    );
}

export default Drive;