import * as React from "react";
import { Field, Select, useId } from "@fluentui/react-components";
import type { FieldProps, SelectProps } from "@fluentui/react-components";
import { APDocumentType } from "../../../enums/APDocumentType.enum";
import { AccountNumber } from "../../../enums/AccountNumber.enum";
import { ActualBudgetForecast } from "../../../enums/ActualBudgetForecast.enum";
import { AssetPortfolios } from "../../../enums/AssetPortfolios.enum";
import { AuditStatus } from "../../../enums/AuditStatus.enum";
import { AuditTypeClassification } from "../../../enums/AuditTypeClassification.enum";
import { Bank } from "../../../enums/Bank.enum";
import { BusinessUnit } from "../../../enums/BusinessUnit.enum";
import { ContractType } from "../../../enums/ContractType.enum";
import { Country } from "../../../enums/Country.enum";
import { CreditControlDocumentDescriptionPrefix } from "../../../enums/CreditControlDocumentDescriptionPrefix.enum";
import { Currency } from "../../../enums/Currency.enum";
import { Department } from "../../../enums/Department.enum";
import { DevelopmentProjectStatus } from "../../../enums/DevelopmentProjectStatus.enum";
import { DocumentClassification } from "../../../enums/DocumentClassification.enum";
import { DocumentDescriptionPrefix } from "../../../enums/DocumentDescriptionPrefix.enum";
import { FOREXCategory } from "../../../enums/FOREXCategory.enum";
import { FacilityNumber } from "../../../enums/FacilityNumber.enum";
import { FinanceDocumentType } from "../../../enums/FinanceDocumentType.enum";
import { FundingDocuments } from "../../../enums/FundingDocuments.enum";
import { InstrumentType } from "../../../enums/InstrumentType.enum";
import { InternalAuditDocumentDescriptionPrefix } from "../../../enums/InternalAuditDocumentDescriptionPrefix.enum";
import { JournalDocumentPrefix } from "../../../enums/JournalDocumentPrefix.enum";
import { LeaseDocumentDescriptionPrefix } from "../../../enums/LeaseDocumentDescriptionPrefix.enum";
import { LegalContractTypes } from "../../../enums/LegalContractTypes.enum";
import { LegalDisposalsAndAquisitionsDocumentType } from "../../../enums/LegalDisposalsAndAquisitionsDocumentType.enum";
import { LegalGeneralDocumentType } from "../../../enums/LegalGeneralDocumentType.enum";
import { LegalRentalArrearsDocumentType } from "../../../enums/LegalRentalArrearsDocumentType.enum";
import { LegalSpecialProjectsDocumentType } from "../../../enums/LegalSpecialProjectsDocumentType.enum";
import { LegalSpecialTransactionsDocumentType } from "../../../enums/LegalSpecialTransactionsDocumentType.enum";
import { LetterTemplate } from "../../../enums/LetterTemplate.enum";
import { localInternational } from "../../../enums/LocalInternational.enum";
import { MatterType } from "../../../enums/MatterType.enum";
import { Months } from "../../../enums/Month.enum";
import { PaymentsDocumentType } from "../../../enums/PaymentsDocumentType.enum";
import { Period } from "../../../enums/Period.enum";
import { Quarterly } from "../../../enums/Quarterly.enum";
import { ReturnType } from "../../../enums/ReturnType.enum";
import { RiskVetting } from "../../../enums/RiskVetting.enum";
import { Sector } from "../../../enums/Sector.enum";
import { SignedUnsigned } from "../../../enums/SignedUnsigned.enum";
import { SupplierStatus } from "../../../enums/SupplierStatus.enum";
import { TaxType } from "../../../enums/TaxType.enum";
import { TrainingMethod } from "../../../enums/TrainingMethod.enum";
import { TypeGrade } from "../../../enums/TypeGrade.enum";
import { UtilitiesProjectType } from "../../../enums/UtilitiesProjectType.enum";
import { Year } from "../../../enums/year.enum";
import { Region } from "../../../enums/Region.enum";
import { useEffect } from "react";
interface Select {
    selectProps: SelectProps;
    fieldProps: FieldProps;
}
export const Default = (props: Select) => {
  const selectId = useId();
  const [tagLabel, setTagLabel] = React.useState('');
  const [selectorOptions, setSelectorOptions] = React.useState([] as any[]);

  useEffect(() => {
    console.log(props.fieldProps.label.toString());
    if (props.fieldProps.label !== undefined) {
      setTagLabel(props.fieldProps.label.toString());
      setSelectorOptions(getClassificationOptionTagValues(props.fieldProps.label.toString()));
    }
  },[]);

  const getClassificationOptionTagValues = (tag: string) => {
    let options: any[] = [];
    let val: any[] = [];
    switch (tag) {
      case 'ActualBudgetForecast':
          val = Object.values(ActualBudgetForecast).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'AssetPorfolios':
          val = Object.values(AssetPortfolios).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'AuditStatus': 
        val = Object.values(AuditStatus).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'AuditTypeClassification':
          val = Object.values(AuditTypeClassification).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Bank':
          val = Object.values(Bank).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'BusinessUnit':
          val = Object.values(BusinessUnit).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'ContractType':
          val = Object.values(ContractType).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Country':
        val = Object.values(Country).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'Currency':
        val = Object.values(Currency).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'Department':
          val = Object.values(Department).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'DevelopmentProjectStatus':
        val = Object.values(DevelopmentProjectStatus).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'DocumentClassification':
          val = Object.values(DocumentClassification).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'DocumentType':
          val = Object.values(DocumentType).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'FacilityNumber':
        val = Object.values(FacilityNumber).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'ForexCategory':
        val = Object.values(FOREXCategory).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'FundingDocuments':
        val = Object.values(FundingDocuments).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'InstrumentType':
        val = Object.values(InstrumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'JournalDocumentPrefix':
          val = Object.values(JournalDocumentPrefix).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'LeaseDocumentDescriptionPrefix':
        val = Object.values(LeaseDocumentDescriptionPrefix).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LetterTemplate':
          val = Object.values(LetterTemplate).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'LocalInternational':
        val = Object.values(localInternational).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'MatterType':
        val = Object.values(MatterType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'Month':
          val = Object.values(Months).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'PaymentsDocumentType':
          val = Object.values(PaymentsDocumentType).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Period':
          val = Object.values(Period).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Quarter':
          val = Object.values(Quarterly).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Quarterly':
          val = Object.values(Quarterly).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Region':
        val = Object.values(Region).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'ReturnType':
        val = Object.values(ReturnType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'Sector':
          val = Object.values(Sector).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'SignedUnsigned':
        val = Object.values(SignedUnsigned).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'SupplierStatus':
          val = Object.values(SupplierStatus).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'TaxType':
        val = Object.values(TaxType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'TrainingMethod':
          val = Object.values(TrainingMethod).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'TypeGrade':
          val = Object.values(TypeGrade).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'UtilitiesProjectType':
          val = Object.values(UtilitiesProjectType).filter((value) => typeof value !== 'number');
          return buildOption(val);
      case 'Year':
      case 'FinanceYear':
      case 'CalenderYear':
          val = Object.values(Year).filter((value) => typeof value == 'number');
          return buildOption(val);
      case 'AccountNumber':
        val = Object.values(AccountNumber).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'APDocumentType': 
        val = Object.values(APDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'CreditControlDocumentDescriptionPrefix':
        val = Object.values(CreditControlDocumentDescriptionPrefix).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'FinanceDocumentType':
        val = Object.values(FinanceDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'InternalAuditDocumentDescriptionPrefix':
        val = Object.values(InternalAuditDocumentDescriptionPrefix).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalContractTypes':
        val = Object.values(LegalContractTypes).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalDisposalsAndAquisitionsDocumentType':
        val = Object.values(LegalDisposalsAndAquisitionsDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalGeneralDocumentType':
        val = Object.values(LegalGeneralDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalRentalArrearsDocumentType':
        val = Object.values(LegalRentalArrearsDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalSpecialProjectsDocumentType':
        val = Object.values(LegalSpecialProjectsDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'LegalSpecialTransactionsDocumentType':
        val = Object.values(LegalSpecialTransactionsDocumentType).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'DocumentDescriptionPrefix':
        val = Object.values(DocumentDescriptionPrefix).filter((value) => typeof value !== 'number');
        return buildOption(val);
      case 'RiskVetting':
        val = Object.values(RiskVetting).filter((value) => typeof value !== 'number');
        return buildOption(val);
      default:
          return val = [];
    }
  }

  const buildOption = (values: any[]) => {
    const options: any[] = [];
    values.map(value => {
      const option = {
        label: value.toString(),
        value: value.toString(),
      }
      options.push(option);
    });
    return options;
  }

  return (
    <Field label={props.fieldProps.label}>
      <Select id={selectId} {...props.selectProps}>
        <option></option>
        {selectorOptions.length > 0 ? selectorOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
            )) : null}
      </Select>
    </Field>
  );
};