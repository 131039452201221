import { useEffect, useState } from "react";
import { getFolders } from "../../../services/endpoint";
import { Dropdown, Option } from "@fluentui/react-components";
import React from "react";
import type { DropdownProps } from "@fluentui/react-components";
interface FolderProps {
    departmentId?: string;
    driverId?: string;
    onFolderChange?: (folder: string) => void;
}
interface ResponseData {
    id: string;
    name: string;
    displayName: string;
    webUrl: string;
}
const Folder = ({
    departmentId,
    driverId,
    onFolderChange,
}: FolderProps, props: Partial<DropdownProps>) => {
    const [folders, setFolders] = useState([] as ResponseData[]);
    const [selectedFolder, setSelectedFolder] = useState({} as ResponseData);
    const [selectedOptions, setSelectedOptions] = useState([] as string[]);
    useEffect(() => {
        const fetchData = async () => {
            setSelectedFolder({} as ResponseData);
            const folder = await getFolders(departmentId, driverId);
            setFolders(folder.data);
        };
        if (departmentId && driverId)
            fetchData();
    }, [departmentId, driverId]);

    const onOptionSelect : (typeof props)["onOptionSelect"] = (ev, data) => {
        console.log(ev, data);
        setSelectedFolder(folders.find((d) => d.id === data.optionValue));
        setSelectedOptions(data.selectedOptions);
        onFolderChange(data.optionValue);
    }
    return (
        <div style={{width: '100%'}}>
            <label id="drp">Folder:</label>
            <Dropdown
                aria-labelledby="drp"
                placeholder="Select Folder"
                style={{ width: "100%" }}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
            >
                {folders.length > 0
                    ? folders.map((folder) => (
                          <Option
                            key={folder.id}
                            value={folder.id}
                            text={folder.name}
                          >
                              {folder.name}
                          </Option>
                      ))
                    : null}
            </Dropdown>
        </div>
    );
}

export default Folder;