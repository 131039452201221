import * as React from "react";
import { Field, Textarea } from "@fluentui/react-components";
import type { FieldProps, TextareaProps } from "@fluentui/react-components";
import { useEffect } from "react";
interface TextArea {
    textAreaProps: TextareaProps;
    fieldProps: FieldProps;
}
export const Default = (props: TextArea) => (
    useEffect(() => {
        console.log(props);
    }),
  <Field label={props.fieldProps.label}>
    <Textarea {...props.textAreaProps} />
  </Field>
);