import React, { useState } from 'react';
import { Department } from './Departments/department';
import Drive from './Departments/drive';
import Folder from './Departments/folder';

interface DepartmentSelectorProps {
    onDepartmentChange: (department: string) => void;
    onDriveChange: (drive: string) => void;
    onFolderChange: (folder: string) => void;
}

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({
    onDepartmentChange,
    onDriveChange,
    onFolderChange,
}) => {
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedDrive, setSelectedDrive] = useState('');
    return (
        <div>
            <Department onDepartmentChange={(department) => {onDepartmentChange(department); setSelectedDepartment(department)}} />
            <Drive departmentId={selectedDepartment} onDriveChange={(drive) => {onDriveChange(drive); setSelectedDrive(drive)}} />
            <Folder
                departmentId={selectedDepartment}
                driverId={selectedDrive}
                onFolderChange={onFolderChange}
            />
        </div>
    );
};

export default DepartmentSelector;