export enum Department {
    'AcquisitionsDisposals',
    'AssetManagement',
    'CompanySecretary',
    'Development',
    'ESG',
    'FacilitiesManagement',
    'Finance',
    'HR',
    'InternalAudit',
    'IT',
    'LearningDevelopment',
    'Legal',
    'Marketing',
    'Non-GLA',
    'OfficeSupport',
    'Procurement',
    'PropertyAdmin',
    'PropertyManagement',
    'RiskCompliance',
    'SustainabilityProjects',
    'Utilities',
}