export enum LegalGeneralDocumentType {
    'Accounts Issued',
    'Accounts Received',
    'Advertisement',
    'Affidavits',
    'Agreement Type',
    'Amended Pleadings',
    'Applications',
    'Client Documents',
    'Correspondence',
    'Counterclaim',
    'Court Order',
    'Credit Notes Issued',
    'Credit Notes Received',
    'Diagrams',
    'Discovery Documents',
    'Documents',
    'Emails Received',
    'Emails Sent',
    'Inventory',
    'Invoices Issued',
    'Invoices Received',
    'Judgment',
    'Letters Received',
    'Letters Sent',
    'Mandate and Indemnity',
    'Meetings',
    'Notes on Hearing',
    'Notice of Bar',
    'Notice of Set Down',
    'Notice to Amend',
    'Notice to Defend',
    'Opinion',
    'Photographs',
    'Plans',
    'Plea',
    'Proof of Payment',
    'Request for Further Particulars',
    'Research',
    'Return of Service',
    'Statements',
    'Summons',
    'Warrant of Execution'
}