import React from "react";
import { useEffect, useState } from "react";
import TagClassifications from "./TagClassifications";
import AutocompleteSearch from "./MdaFields/Portfolio";
import PropertySearch from "./MdaFields/Property";
import Tenant from "./MdaFields/Tenant";
import CashBook from "./MdaFields/CashBook";
import OwnerEntity from "./MdaFields/OwnerEntity";
import PropertyType from "./MdaFields/PropertyType";
import Supplier from "./MdaFields/Supplier";
import { Property } from "../../models/property.model";
import { ClassificationTag, ClassificationTagFactory, IClassificationTag } from "../../models/classification.model";
import { Item } from "../../models/item.model";
import { uploadFileMetaData } from "../../services/endpoint";
import { TagService } from "../../services/tag.service";
import { Toast, ToastTitle, useId, useToastController } from "@fluentui/react-components";
interface FormTagModel {
    classificationID: string
    classificationName: string
    containerID: string
    containerName: string
    requiredTag: string
    showTag: string
    type: string
}

interface TagsProps {
    onClassificationChange?: (tagValues: any) => void;
    folderId: string;
    uploadedFileInfo?: any[];
}

export const Tags = ({folderId, uploadedFileInfo}: TagsProps) => {
    const toasterId = useId("toaster");
    const [tags, setTags] = useState([] as FormTagModel[]);
    const [tagValues, setTagValues] = useState({} as IClassificationTag);
    const [selectedProperty, setSelectedProperty] = useState({} as Property);
    const { dispatchToast } = useToastController(toasterId);
    const tagService = new TagService();
    useEffect(() => {
        if (folderId !== undefined) {
            getTags();
        }
    }, [folderId]);

    useEffect(() => {
       // check if the uploaded file info object is not empty
         if (uploadedFileInfo?.length === 0) 
            return;
        console.log(uploadedFileInfo);
        // loop through the uploaded file info object and add the values to the new item object
        const newFileUploadList = uploadedFileInfo.map((file) => {        
        const newItem = new Item();
        newItem.name = file.name;
        newItem.size = file.size.toString();
        newItem.containerID = folderId;
        newItem.createdBy = file.createdBy?.user.displayName;
        newItem.createdDate = file.createdDateTime;
        newItem.modifiedBy = file.lastModifiedBy?.user.displayName;
        newItem.modifiedDate = file.lastModifiedDateTime;
        newItem.itemInputTypeID = file.file?.mimeType
        newItem.id = file.id;
        // if the property journalNumber is not empty in the classification tag object add a prefix to the name
        if (tagValues.JournalNumber?.length > 0) {
            // check if the jurnal already has a prefix
            if (!tagValues.JournalNumber.startsWith('JNL'))
                tagValues.JournalNumber = "JNL_" + tagValues.JournalNumber;
        }
        const classificationJson : ClassificationTagFactory = (tagValues);
        // loop through the classification tag object and convert the values to a string
        Object.keys(tagValues).forEach((key) => {
            classificationJson[key] = tagValues[key].toString();
        });
        newItem.classificationJson = classificationJson;
        return newItem;
        });
        saveTagData(newFileUploadList);
    }, [uploadedFileInfo]);

    useEffect(() => {
        console.log(tagValues);
    }, [tagValues]);

    const saveTagData = async (newFiles: Item[]) => {
        if (newFiles.some(file => file.id !== undefined && file.id !== null)) {
            try {
                const uploadResponse = await uploadFileMetaData(newFiles);
                console.log(uploadResponse);
                // if the response is successful then close the add in
                if (uploadResponse.isSuccess) {
                    // show a toast message to the user
                    dispatchToast(
                        <Toast>
                            <ToastTitle>Files uploaded successfully.</ToastTitle>
                        </Toast>,
                        { intent: "success" }
                    );
                    setTimeout(() => {
                        Office.context.ui.closeContainer();
                    }, 5000);
                } else {
                    console.error('Error uploading file:', uploadResponse);
                    // show a toast message to the user
                    dispatchToast(
                        <Toast>
                            <ToastTitle>Error uploading files to database.</ToastTitle>
                        </Toast>,
                        { intent: "error" }
                    );
                }
            } catch (error) {
                console.error('Error uploading attachment:', error);
                throw error;
            }
        }
    }


    const rollbackFileUpload = async (files: Item[]) => {
        try {
            for (const file of files) {
                const response = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${file.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                    }
                });
                const data = await response.json();
                console.log(data);
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    }

    const getTags = () => {
        tagService.getTags(folderId).then((data) => {
            if (data.isSuccess) {
                setTags(data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleTagClick = (tag: any) => {
        console.log(tag);
        const key = Object.keys(tag)[0];
        const value = Object.values(tag)[0];

        setTagValues(prevTagValues => {
            if (value !== null) {
                return { ...prevTagValues, [key]: value.toString() };
            } else {
                return { ...prevTagValues, [key]: null };
            }
        });

        console.log(tagValues);
    }

    const handleMDAChange = (value: any, fieldName: string) => {
        // Create a new state object with the updated name
        const newTagValues = { ...tagValues, [fieldName]: value.toString() };
        // fill the class object with the selected value
        setTagValues(newTagValues);
    }

    const handleMDAPortfolioChange = (value: any, fieldName: string) => {
        // check if the PortfolioID is not set in the tag values
        if (tagValues.PortfolioID === undefined || tagValues.PortfolioID === null) {
        // Create a new state object with the updated name
        const newTagValues = { ...tagValues, [fieldName]: value.toString() };
        // fill the class object with the selected value
        setTagValues(newTagValues);
        } else {
            // check if the PortfoloID is not the same as the selected value
            if (tagValues.PortfolioID !== value.toString()) {
                // Create a new state object with the updated name
                const newTagValues = { ...tagValues, [fieldName]: value.toString() };
                // fill the class object with the selected value
                setTagValues(newTagValues);
            }
        }
    }

    const handlePropertyChange = (value: any, fieldName: string) => {
        setSelectedProperty(value);
        const newTagValues = { ...tagValues, [fieldName]: value.propertyID.toString() };
        setTagValues(newTagValues);
    }

    const handleTenantPropertyChange = (tenantId: any, propertyId: any) => {
        console.log(tenantId, propertyId);
        // check if the selected property is a empty object
        if (Object.keys(selectedProperty).length === 0) {
            const newTagValues = { ...tagValues, 'TenantID': tenantId, 'PropertyID': propertyId };
            // set the property id and the tenant id in the tag values
            setTagValues(newTagValues);
        }
    }

    return (
        <div style={{paddingBottom: 2}}>
            {tags.length > 0 ? 
            <>
                { tags.findIndex(x => x.classificationName == 'PropertyNameCode') !== -1 ?  <PropertySearch propertyID={tagValues?.PropertyID} onPropertyChange={(e) => {handlePropertyChange(e, 'PropertyID')}} /> : null }
                { tags.findIndex(x => x.classificationName == 'TenantCode') !== -1 ? <Tenant propertyId={selectedProperty?.propertyID} onTenantChange={(e) => {handleMDAChange(e, 'TenantID')}} 
                onPropertyTenantChange={(e,d) => handleTenantPropertyChange(e,d)}
                /> : null }
                { tags.findIndex(x => x.classificationName == 'Supplier') !== -1 ? <Supplier onSupplierChange={(e) => handleMDAChange(e, 'SupplierID')}/> : null} 
                { tags.findIndex(x => x.classificationName == 'Portfolioname') !== -1 &&  selectedProperty?.portfolioID ? <AutocompleteSearch portfolioID={selectedProperty.portfolioID} onPortfolioChange={(e) => handleMDAPortfolioChange(e, 'PortfolioID')}/> : null }
                { tags.findIndex(x => x.classificationName == 'CashBook') !== -1 && selectedProperty?.cashBookID? <CashBook cashBookID={selectedProperty.cashBookID} onCashBookChange={(e) => handleMDAChange(e, 'CashBook')}/> : null }
                { tags.findIndex(x => x.classificationName == 'PropertyType') !== -1 && selectedProperty?.propertyTypeID ? <PropertyType propertyTypeID={selectedProperty.propertyTypeID} onPropertyTypeChange={(e) => handleMDAChange(e, 'PropertyType')}/> : null}
                { tags.findIndex(x => x.classificationName == 'OwnerEntity') !== -1 && selectedProperty?.ownerEntityID ? <OwnerEntity ownerEntityID={selectedProperty.ownerEntityID} onOwnerEntityChange={(e) =>handleMDAChange(e, 'OwnerEntity')}/> : null}
                <TagClassifications formData={tags} onTagChange={handleTagClick}/>
            </>
            : 
            <>
                <h2>No tags found</h2>
                <p>Inform Administrator</p>
            </>
            }     
                </div>
    );

}
