import { ComboboxProps, Field, Combobox, Option } from "@fluentui/react-components";
import React , {useState, useEffect} from "react";

interface Supplier {
    supplierID: string;
    supplierCode: string;
    supplierName: string;
}

interface SupplierProps {
    onSupplierChange: (supplierId: string) => void;
}

const Supplier = ({onSupplierChange}: SupplierProps, props: Partial<ComboboxProps>) => {
    const [matchingOptions, setMatchingOptions] = React.useState([] as Supplier[]);
    const [suppliers, setSuppliers] = React.useState([] as Supplier[]);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                let url = `https://localhost:44392/api/MDA/Suppliers`;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setSuppliers(data.data);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const handleSearchTermChange: ComboboxProps['onChange'] = (event) => {
        const value = event.target.value.trim();
        const matches = matchingOptions.filter((option) => {
            if (!isNaN(parseInt(value))) {
                return option.supplierCode.toString().includes(value.toLowerCase());
            } else {
                return option.supplierName.toLowerCase().includes(value.toLowerCase());
            }
        });
        setMatchingOptions(matches);
    }

    const handleSelection = (event, data) => {
        console.log(data, event);
        onSupplierChange(data.optionValue);
    }

    return (
        <>
            <Field label="Supplier">
                <Combobox
                    placeholder="Search for a supplier"
                    id="supplier"
                    onChange={handleSearchTermChange}
                    onOptionSelect={handleSelection}
                    {...props}>
                        {matchingOptions.length == 0 && suppliers.map((option) => (
                            <Option key={option.supplierID} value={option.supplierID} text={option.supplierName.trim()}>
                                {option.supplierName.trim()}
                            </Option>
                        ))}
                        {matchingOptions.length > 0 && matchingOptions.map((option) => (
                            <Option key={option.supplierID} value={option.supplierID} text={option.supplierName.trim()}>
                                {option.supplierName.trim()}
                            </Option>
                        ))}
                    </Combobox>
            </Field>
        </>
    );
}

export default Supplier;