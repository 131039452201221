export enum LegalRentalArrearsDocumentType {
    'Accounts Issued',
    'Accounts Received',
    'Acknowledgement of Debt',
    'Addendums/Annexures',
    'Advertisement',
    'Affidavits',
    'Amended Pleadings',
    'Applications',
    'Auditors Certificate',
    'Balance Sheet',
    'Bank Guarantee(s)',
    'Business Affairs Documents',
    'Business Rescue Plan',
    'Business Rescue Plan Certificate',
    'Cancellation Agreement',
    'Certificate of Winding-Up',
    'Cession Agreement',
    'Company Affairs Documents',
    'Confirmaton of Removal from Register',
    'Correspondence',
    'Counterclaim',
    'Court Order',
    'Courtesy Letter',
    'Credit Notes Issued',
    'Credit Notes Received',
    'Diagrams',
    'Discovery Documents',
    'Documents',
    'Emails Received',
    'Emails Sent',
    'Financial Affairs Documents',
    'Income and Expenses',
    'Informal Proposal Statement',
    'Inventory',
    'Invoices Issued',
    'Invoices Received',
    'Issued Securities',
    'Judgment',
    'Lease Agreement',
    'Letter of Demand',
    'Letters Received',
    'Letters Sent',
    'List of Assets',
    'Mandate and Indemnity',
    'Meeting Notices',
    'Meetings',
    'Notes on Hearing',
    'Notice of Appointment',
    'Notice of Bar',
    'Notice of Implemenation',
    'Notice of Meetings',
    'Notice of Set Down',
    'Notice to Amend',
    'Notice to Defend',
    'Photographs',
    'Plans',
    'Plea',
    'Proof of Lodgement',
    'Proof of Payment',
    'Property Affairs Documents',
    'Remuneration Agreement',
    'Request for Further Particulars',
    'Resolution',
    'Response to Letter of Demand',
    'Return of Service',
    'Rights of Employee Documentation',
    'Security Documents',
    'Settlement Agreement',
    'Statement of Account Issued',
    'Statement of Account Received',
    'Statement of Conditions of BRP',
    'Statement of Transactions',
    'Statements',
    'Summons',
    'Surety Agreement',
    'Verbal Correspondence',
    'Warrant of Execution'
}