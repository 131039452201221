export enum TypeGrade {
    'PremiumGrade',
    'SecondaryGrade',
    'AGrade',
    'SuperReginalShoppingCenter',
    'RegionalShoppingCentre',
    'ConvenienceShoppingCentre',
    'Other',
    'Warehousing',
    'LightManufacturing',
    'HeavyGradeIndustrial',
    'IndustrialUnits',
    'ModernLogistics',
    'HiTechIndustrial',
    'RetailWarehouse',
    'VacantLand/Sites',
}