export enum LegalSpecialTransactionsDocumentType {
'Accounts Issued',
'Accounts Received',
'Agreement',
'Annexure(s)',
'Asset Documentation',
'Bank Guarantee(s)',
'Correspondence',
'Credit Notes Issued',
'Credit Notes Received',
'Creditor Documentation',
'Debtor Documentation',
'Diagrams',
'Documents',
'Emails Received',
'Emails Sent',
'Employee Documentation',
'Invoices Issued',
'Invoices Received',
'Letters Received',
'Letters Sent',
'Liability Documentation',
'Meetings',
'Miscellaneous',
'Notes',
'Photographs',
'Plans',
'Proof of Payment',
'Resolution(s)',
'Resolutions',
'Statement of Account Issued',
'Statement of Account Received',
'Utilities Documentation',
'Verbal Correspondence'
}