import { Dropdown, Option } from "@fluentui/react-components";
import React, { useState, useEffect } from "react";
import { getDepartments } from "../../../services/endpoint";
import type { DropdownProps } from "@fluentui/react-components";

interface ResponseData {
    id: string;
    name: string;
    displayName: string;
    webUrl: string;
}

interface DepartmentProps {
    onDepartmentChange?: (depaerment: string) => void;
}

export const Department = ({ onDepartmentChange }: DepartmentProps, props: Partial<DropdownProps>) => {
    const [departments, setDepartments] = useState([] as ResponseData[]);
    const [selectedDepartment, setSelectedDepartment] = useState({} as ResponseData);
    const [selectedOptions, setSelectedOptions] = useState([] as string[]);
    useEffect(() => {
        const fetchData = async () => {
            setSelectedDepartment({} as ResponseData);
            const department = await getDepartments();
            setDepartments(department.data);
        };
        fetchData();
    }, []);

    const onOptionSelect : (typeof props)["onOptionSelect"] = (ev, data) => {
        console.log(ev, data);
        setSelectedDepartment(departments.find((d) => d.id === data.optionValue));
        setSelectedOptions(data.selectedOptions);
        onDepartmentChange(data.optionValue);
    };
    return (
        <div style={{width: '100%'}}>
            <label id="drp">Department:</label>
            <Dropdown
                aria-labelledby="drp"
                placeholder="Select Department"
                style={{ width: "100%" }}
                selectedOptions={selectedOptions}
                onOptionSelect={onOptionSelect}
            >
                {departments.length > 0
                    ? departments.map((department) => (
                          <Option
                            key={department.id}
                            value={department.id}
                            text={selectedDepartment.displayName}
                          >
                              {department.displayName}
                          </Option>
                      ))
                    : null}
            </Dropdown>
        </div>
    );
}
